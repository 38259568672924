import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Motorista.css';

function MotoristaList({ onAddClick, isAddFormVisible }) {
  const [motoristas, setMotoristas] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [currentMotorista, setCurrentMotorista] = useState(null);
  const [userSkillLevel, setUserSkillLevel] = useState('');

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = JSON.parse(atob(token.split('.')[1]));
        setUserSkillLevel(decoded.skill_level || '');
      }
    };

    fetchUserPermissions();
    fetchMotoristas();
  }, []);

  const fetchMotoristas = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/motoristas`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setMotoristas(response.data);
    } catch (error) {
      console.error('Erro ao buscar motoristas:', error);
    }
  };

  const handleOpenModal = (motorista) => {
    setCurrentMotorista(motorista);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setCurrentMotorista(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentMotorista({ ...currentMotorista, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      const updatedMotorista = {
        ...currentMotorista,
        cpf: currentMotorista.cpf.replace(/\D/g, ''), // Remove a formatação do CPF para salvar no banco
        whatsapp: currentMotorista.whatsapp.replace(/\D/g, '') // Remove a formatação do WhatsApp para salvar no banco
      };
      await axios.put(`${process.env.REACT_APP_API_URL}/motoristas/${currentMotorista.id}`, updatedMotorista, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchMotoristas();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao atualizar motorista:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Tem certeza que deseja excluir este motorista?")) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/motoristas/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        fetchMotoristas();
        alert('Motorista excluído com sucesso!');
      } catch (error) {
        console.error('Erro ao excluir motorista:', error);
        alert('Erro ao excluir motorista');
      }
    }
  };

  const formatCPF = (cpf) => {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatWhatsapp = (whatsapp) => {
    return whatsapp.replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, "+$1 $2 $3 $4-$5");
  };

  return (
    <div className="mot-list-container">
      <h2 className="mot-list-title">Lista de Motoristas</h2>
      <div className="mot-list-controls">
        {/* Botão "Adicionar Motorista" visível apenas para AGENCIA e PROGRAMACAO */}
        {(userSkillLevel === 'agencia' || userSkillLevel === 'programacao') && (
          <button className="mot-add-button" onClick={onAddClick}>
            {isAddFormVisible ? 'Cancelar' : 'Adicionar Motorista'}
          </button>
        )}
        <div className="mot-list-search">
          <input
            type="text"
            placeholder="Buscar por nome ou CPF"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="mot-list-search-input"
          />
        </div>
      </div>

      <div className="mot-table-wrapper">
        <table className="mot-list-table">
          <thead>
            <tr className="mot-column-header">
              <th className="mot-row-number">#ID</th>
              <th>Ações</th>
              <th>Nome</th>
              <th>CPF</th>
              <th>WhatsApp</th>
            </tr>
          </thead>
          <tbody>
            {motoristas.filter(motorista => 
              motorista.nome.toLowerCase().includes(searchTerm.toLowerCase()) || 
              motorista.cpf.includes(searchTerm)
            ).map((motorista, index) => (
              <tr key={motorista.id}>
                <td className="mot-row-number">{motorista.id}</td>
                <td>
                  {/* Botões de editar e deletar visíveis apenas para AGENCIA e PROGRAMACAO */}
                  {(userSkillLevel === 'agencia' || userSkillLevel === 'programacao') && (
                    <>
                      <button onClick={() => handleOpenModal(motorista)} className="mot-list-edit-button">✏️</button>
                      <button onClick={() => handleDelete(motorista.id)} className="mot-list-delete-button">🗑️</button>
                    </>
                  )}
                </td>
                <td>{motorista.nome}</td>
                <td>{formatCPF(motorista.cpf)}</td>
                <td>{formatWhatsapp(motorista.whatsapp)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {open && (
        <div className="mot-modal-overlay">
          <div className="mot-modal-content">
            <h3 className="mot-modal-title">Editar Motorista</h3>
            <div className="mot-form-group">
              <label className="mot-form-label">Nome</label>
              <input
                type="text"
                name="nome"
                value={currentMotorista?.nome || ''}
                onChange={handleInputChange}
                className="mot-form-input"
              />
            </div>
            <div className="mot-form-group">
              <label className="mot-form-label">CPF</label>
              <input
                type="text"
                name="cpf"
                value={formatCPF(currentMotorista?.cpf || '')}
                onChange={handleInputChange}
                className="mot-form-input"
                maxLength={14} // Limita a 14 caracteres no formato 000.000.000-00
              />
            </div>
            <div className="mot-form-group">
              <label className="mot-form-label">WhatsApp</label>
              <input
                type="text"
                name="whatsapp"
                value={formatWhatsapp(currentMotorista?.whatsapp || '')}
                onChange={handleInputChange}
                className="mot-form-input"
                maxLength={20} // Limita a 20 caracteres no formato +55 11 9 8992-9199
              />
            </div>
            <div className="mot-modal-actions">
              <button onClick={handleCloseModal} className="mot-button mot-button-secondary">Cancelar</button>
              <button onClick={handleUpdate} className="mot-button mot-button-primary">Salvar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MotoristaList;
