import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Navbar.css';
import logo from '../img/LOGO.webp'; // Certifique-se de ter um logo e ajuste o caminho conforme necessário

function Navbar() {
  const [isSticky, setIsSticky] = useState(false);
  const [username, setUsername] = useState('');
  const navigate = useNavigate(); // Hook para redirecionamento

  useEffect(() => {
    // Função para lidar com o scroll
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    // Evento de scroll para ajustar a navbar
    window.addEventListener('scroll', handleScroll);

    // Limpeza do evento ao desmontar o componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Recuperar o nome de usuário do localStorage
    const storedUsername = localStorage.getItem('username');
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);

  const handleLogout = () => {
    // Remover dados do localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    // Redirecionar para a página de login
    navigate('/login');
  };

  return (
    <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
      <div className="navbar-container">
        <div className="logo">
          <img src={logo} alt="Logo" />
        </div>
        <ul className="nav-menu">
          <li><Link to="/cadastro-comercial">Clientes</Link></li>
          <li><Link to="/cadastro-motorista">Motoristas</Link></li>
          <li><Link to="/importacao">Importação</Link></li>
          <li><Link to="/relatorio">Relatório</Link></li>
          
        </ul>
        <div className="user-actions">
          {username && <div className="user-info">Logado como: {username}</div>}
          <button onClick={handleLogout} className="logout-button">Sair</button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
