import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import '../styles/addmodal.css';

const AddModal = ({ isOpen, onClose, onAdd }) => {
  const [formData, setFormData] = useState({
    data: '',
    follow_up: 'PEN', // Status fixo como "PEN"
    origem: '',
    cidade_origem: '',
    entrega: '' // Campo "Entrega" substituindo "Referência"
  });

  const [clientes, setClientes] = useState([]);

  useEffect(() => {
    fetchClientes();
  }, []);

  const fetchClientes = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/clientes`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClientes(response.data);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleClienteChange = (selectedOption, field) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        [field]: `${selectedOption.nome_cliente} - ${selectedOption.cnpj} - ${selectedOption.endereco}`
      });
    } else {
      setFormData({
        ...formData,
        [field]: ''
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formattedData = {
      ...formData,
      data: formData.data ? new Date(formData.data).toISOString() : null // Formatação correta para o campo "Data"
    };
    onAdd(formattedData);
    setFormData({
      data: '',
      follow_up: 'PEN',
      origem: '',
      cidade_origem: '',
      entrega: '' // Campo "Entrega" resetado
    });
  };

  if (!isOpen) return null;

  return (
    <div className="add-modal-overlay">
      <div className="add-modal">
        <span className="add-modal-close" onClick={onClose}>&times;</span>
        <h2 className="add-modal-title">Adicionar Novo Registro de Importação</h2>
        <form onSubmit={handleSubmit} className="add-modal-content">
          <div className="add-modal-form-group">
            <label className="add-modal-label">Data:</label>
            <input
              className="add-modal-input"
              type="date" // Formato de data ajustado
              name="data"
              value={formData.data}
              onChange={handleChange}
            />
          </div>
          <div className="add-modal-form-group">
            <label className="add-modal-label">Follow Up:</label>
            <input
              className="add-modal-input read-only"
              type="text"
              name="follow_up"
              value={formData.follow_up}
              readOnly
            />
          </div>
          <div className="add-modal-form-group">
            <label className="add-modal-label">Origem:</label>
            <Select
              options={clientes}
              getOptionLabel={(cliente) => `${cliente.nome_cliente} - ${cliente.cnpj} - ${cliente.endereco}`}
              getOptionValue={(cliente) => cliente.id}
              value={clientes.find(c => formData.origem && c.nome_cliente === formData.origem.split(' - ')[0])}
              onChange={(option) => handleClienteChange(option, 'origem')}
              isClearable
              placeholder="Selecione o cliente..."
            />
          </div>
          <div className="add-modal-form-group">
            <label className="add-modal-label">Cidade Origem:</label>
            <input
              className="add-modal-input"
              type="text"
              name="cidade_origem"
              value={formData.cidade_origem}
              onChange={handleChange}
            />
          </div>
          <div className="add-modal-form-group">
            <label className="add-modal-label">Entrega:</label>
            <Select
              options={clientes}
              getOptionLabel={(cliente) => `${cliente.nome_cliente} - ${cliente.cnpj} - ${cliente.endereco}`}
              getOptionValue={(cliente) => cliente.id}
              value={clientes.find(c => formData.entrega && c.nome_cliente === formData.entrega.split(' - ')[0])}
              onChange={(option) => handleClienteChange(option, 'entrega')}
              isClearable
              placeholder="Selecione o cliente..."
            />
          </div>
          <div className="add-modal-actions">
            <button type="button" onClick={onClose} className="add-modal-button add-modal-button-cancel">Cancelar</button>
            <button type="submit" className="add-modal-button add-modal-button-add">Adicionar</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddModal;
