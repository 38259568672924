import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Cavalo.css';

function CavaloList({ onAddClick, isAddFormVisible }) {
  const [cavalos, setCavalos] = useState([]);
  const [currentCavalo, setCurrentCavalo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false); // Estado para controlar o modal
  const [userSkillLevel, setUserSkillLevel] = useState('');

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = JSON.parse(atob(token.split('.')[1]));
        setUserSkillLevel(decoded.skill_level || '');
      }
    };

    fetchUserPermissions();
    fetchCavalos();
  }, []);

  const fetchCavalos = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cavalo`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCavalos(response.data);
    } catch (error) {
      console.error('Erro ao buscar cavalos:', error);
    }
  };

  const handleOpenModal = (cavalo) => {
    setCurrentCavalo(cavalo);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setCurrentCavalo(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCavalo({ ...currentCavalo, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/cavalo/${currentCavalo.id}`, {
        placa: currentCavalo.placa // Enviando somente o campo placa
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchCavalos();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao atualizar cavalo:', error);
      alert('Erro ao atualizar cavalo. Por favor, tente novamente.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir este cavalo?')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/cavalo/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchCavalos();
        alert('Cavalo excluído com sucesso!');
      } catch (error) {
        console.error('Erro ao excluir cavalo:', error);
        alert('Erro ao excluir cavalo');
      }
    }
  };

  return (
    <div className="cavalo-list-container">
      <h2 className="cavalo-list-title">Lista de Cavalos</h2>
      <div className="cavalo-list-controls">
        {/* Exibe o botão "Adicionar Cavalo" apenas para AGENCIA e PROGRAMACAO */}
        {(userSkillLevel === 'agencia' || userSkillLevel === 'programacao') && (
          <button className="cavalo-add-button" onClick={onAddClick}>
            {isAddFormVisible ? 'Cancelar' : 'Adicionar Cavalo'}
          </button>
        )}
        
        <div className="cavalo-list-search">
          <input
            type="text"
            placeholder="Buscar por placa"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="cavalo-list-search-input"
          />
        </div>
      </div>
      
      <div className="cavalo-table-wrapper">
        <table className="cavalo-list-table">
          <thead>
            <tr className="mot-column-header">
              <th className="mot-row-number">#ID</th>
              <th>Ações</th>
              <th>Placa</th>
            </tr>
          </thead>
          <tbody>
            {cavalos.filter(cavalo => 
              cavalo.placa.toLowerCase().includes(searchTerm.toLowerCase())
            ).map((cavalo) => (
              <tr key={cavalo.id}>
                <td className="mot-row-number">{cavalo.id}</td>
                <td>
                  {/* Exibe os botões de editar e deletar apenas para AGENCIA e PROGRAMACAO */}
                  {(userSkillLevel === 'agencia' || userSkillLevel === 'programacao') && (
                    <>
                      <button onClick={() => handleOpenModal(cavalo)} className="cavalo-list-edit-button">✏️</button>
                      <button onClick={() => handleDelete(cavalo.id)} className="cavalo-list-delete-button">🗑️</button>
                    </>
                  )}
                </td>
                <td>{cavalo.placa}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {open && (
        <div className="cavalo-modal-overlay">
          <div className="cavalo-modal-content">
            <h3 className="cavalo-modal-title">Editar Cavalo</h3>
            <div className="cavalo-form-group">
              <label className="cavalo-form-label">Placa</label>
              <input
                type="text"
                name="placa"
                value={currentCavalo?.placa || ''}
                onChange={handleInputChange}
                className="cavalo-form-input"
              />
            </div>
            <div className="cavalo-modal-actions">
              <button onClick={handleCloseModal} className="cavalo-button cavalo-button-secondary">Cancelar</button>
              <button onClick={handleUpdate} className="cavalo-button cavalo-button-primary">Salvar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CavaloList;
