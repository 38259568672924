import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CadastroComercial from './pages/CadastroComercial';
import CriarPedido from './pages/CriarPedido';
import CadastroMotorista from './pages/CadastroMotorista';
import Importacao from './pages/Importacao';
import Navbar from './components/Navbar';
import Login from './pages/Login';
import ProtectedRoute from './components/ProtectedRoute';
import Relatorio from './components/Relatorio';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/cadastro-comercial"
          element={
            <ProtectedRoute>
              <CadastroComercial />
            </ProtectedRoute>
          }
        />
        <Route
          path="/criar-pedido"
          element={
            <ProtectedRoute>
              <CriarPedido />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cadastro-motorista"
          element={
            <ProtectedRoute>
              <CadastroMotorista />
            </ProtectedRoute>
          }
        />
        <Route
          path="/importacao"
          element={
            <ProtectedRoute>
              <Importacao />
            </ProtectedRoute>
          }
        />
        <Route
          path="/relatorio"
          element={
            <ProtectedRoute>
              <Relatorio />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Login />} /> {/* Redireciona para login por padrão */}
      </Routes>
    </Router>
  );
}

export default App;
