import React, { useState } from 'react';
import ClienteForm from '../components/ClienteForm';
import ClienteList from '../components/ClienteList';
import '../styles/cliente.css';

function CadastroComercial() {
  const [isAddFormVisible, setAddFormVisible] = useState(false);

  const toggleAddForm = () => {
    setAddFormVisible(!isAddFormVisible);
  };

  return (
    <div className="cli-page-container">
      
      {isAddFormVisible && (
        <div className="cli-modal-overlay">
          <div className="cli-modal-content cli-form-wrapper">
            <h2 className="cli-modal-title">Cadastrar Novo Cliente</h2>
            <div className="cli-form-scroll-wrapper">
              <ClienteForm onClose={toggleAddForm} />
            </div>
          </div>
        </div>
      )}
      <div className="cli-list-wrapper">
        <ClienteList onAddClick={toggleAddForm} isAddFormVisible={isAddFormVisible} />
      </div>
    </div>
  );
}

export default CadastroComercial;