import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import '../styles/Cavalo.css';

function CavaloForm({ onClose }) {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = async (data) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`${process.env.REACT_APP_API_URL}/cavalo`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      alert('Cavalo cadastrado com sucesso!');
      onClose();
    } catch (error) {
      console.error('Erro ao cadastrar cavalo:', error.response ? error.response.data : error.message);
      alert('Erro ao cadastrar cavalo: ' + (error.response ? error.response.data.error : error.message));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="cavalo-form">
      <div className="cavalo-form-group">
        <label>Placa </label>
        <input {...register('placa', { required: true })} />
        {errors.placa && <span>Este campo é obrigatório</span>}
      </div>
      <div className="cavalo-form-actions">
      <button type="submit" className="cavalo-button cavalo-button-primary">Cadastrar Cavalo</button>
      <button type="button" onClick={onClose} className="cavalo-button cavalo-button-secondary">Cancelar</button>
      </div>
    </form>
  );
}

export default CavaloForm;
