import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import '../styles/cliente.css';

function ClienteList({ onAddClick, isAddFormVisible }) {
  const [clientes, setClientes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [currentCliente, setCurrentCliente] = useState(null);
  const [selectedTipoFaturamento, setSelectedTipoFaturamento] = useState(null);
  const [userSkillLevel, setUserSkillLevel] = useState('');

  const followupOptions = [
    { value: 'Email', label: 'E-mail' },
    { value: 'Whatsapp', label: 'WhatsApp' },
  ];

  const tipoFaturamentoOptions = [
    { value: 'Boleto', label: 'Boleto' },
    { value: 'Pix', label: 'Pix' },
    { value: 'Transferência', label: 'Transferência' },
  ];

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = JSON.parse(atob(token.split('.')[1]));
        setUserSkillLevel(decoded.skill_level || '');
      }
    };

    fetchUserPermissions();
    fetchClientes();
  }, []);

  const fetchClientes = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/clientes`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClientes(response.data);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    }
  };

  const handleOpenModal = (cliente) => {
    // Convertendo o campo `cliente_followup` para o formato que o `react-select` espera
    const selectedFollowupOptions = cliente.cliente_followup?.map(option =>
      followupOptions.find(followup => followup.value === option)
    ) || [];

    // Configurando o campo `tipo_faturamento` para o formato do `react-select`
    const selectedTipoFaturamento = tipoFaturamentoOptions.find(option => option.value === cliente.tipo_faturamento);

    setCurrentCliente({ ...cliente, cliente_followup: selectedFollowupOptions });
    setSelectedTipoFaturamento(selectedTipoFaturamento);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setCurrentCliente(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCliente({ ...currentCliente, [name]: value });
  };

  const handleFollowupChange = (selectedOptions) => {
    setCurrentCliente({ ...currentCliente, cliente_followup: selectedOptions });
  };

  const handleTipoFaturamentoChange = (selectedOption) => {
    setSelectedTipoFaturamento(selectedOption);
    setCurrentCliente({ ...currentCliente, tipo_faturamento: selectedOption.value });
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      const updatedCliente = {
        ...currentCliente,
        cliente_followup: currentCliente.cliente_followup.map(option => option.value),
        tipo_faturamento: selectedTipoFaturamento?.value,
      };
      await axios.put(`${process.env.REACT_APP_API_URL}/clientes/${currentCliente.id}`, updatedCliente, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchClientes();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao atualizar cliente:', error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Tem certeza que deseja excluir este cliente?")) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/clientes/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        fetchClientes();
        alert('Cliente excluído com sucesso!');
      } catch (error) {
        console.error('Erro ao excluir cliente:', error);
        alert('Erro ao excluir cliente');
      }
    }
  };

  return (
    <div className="cli-list-container">
      <h2 className="cli-list-title">Lista de Clientes</h2>
      <div className="cli-list-controls">
        {/* Exibe o botão somente para usuários com a permissão de comercial */}
        {userSkillLevel === 'comercial' && (
          <button className="cli-add-button" onClick={onAddClick}>
            {isAddFormVisible ? 'Cancelar' : 'Adicionar Cliente'}
          </button>
        )}
        <div className="cli-list-search">
          <input
            type="text"
            placeholder="Buscar por nome ou CNPJ"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="cli-list-search-input"
          />
        </div>
      </div>

      <div className="cli-table-wrapper">
        <table className="cli-list-table">
          <thead>
            <tr className="cli-column-header">
              <th className="cli-row-number">#ID</th>
              <th>Ações</th>
              <th>Tipo</th>
              <th>Nome do Cliente</th>
              <th>CNPJ</th>
              <th>Endereço</th>
              <th>Horário de Entrega</th>
              <th>Follow-up</th>
              <th>Rodotrem</th>
              <th>Troca de Nota</th>
              <th>Carga/Descarga</th>
              <th>Escolta</th>
              <th>Pré-Stacking</th>
              <th>Entrega Adicional</th>
              <th>Observação</th>
              <th>Prazo de Faturamento</th>
              <th>Tipo de Faturamento</th>
              <th>Email de Faturamento</th>
            </tr>
          </thead>
          <tbody>
            {clientes.filter(cliente =>
              cliente.nome_cliente.toLowerCase().includes(searchTerm.toLowerCase()) ||
              cliente.cnpj.includes(searchTerm)
            ).map((cliente) => (
              <tr key={cliente.id}>
                <td className="cli-row-number">{cliente.id}</td>
                <td>
                  {userSkillLevel === 'comercial' && (
                    <button onClick={() => handleOpenModal(cliente)} className="cli-list-edit-button">✏️</button>
                  )}
                  {/* Botão de deletar: exibido apenas para usuários com skill 'comercial' */}
                  {userSkillLevel === 'comercial' && (
                    <button onClick={() => handleDelete(cliente.id)} className="cli-list-delete-button">🗑️</button>
                  )}
                </td>
                <td>{cliente.tipo}</td>
                <td>{cliente.nome_cliente}</td>
                <td>{cliente.cnpj}</td>
                <td>{cliente.endereco}</td>
                <td>{cliente.horario_entrega}</td>
                <td>{cliente.cliente_followup?.join(', ')}</td>
                <td>{cliente.rodotrem}</td>
                <td>{cliente.troca_de_nota}</td>
                <td>{cliente.carga_descarga}</td>
                <td>{cliente.escolta}</td>
                <td>{cliente.pre_stacking}</td>
                <td>{cliente.entrega_adicional}</td>
                <td>{cliente.observacao}</td>
                <td>{cliente.prazo_faturamento}</td>
                <td>{cliente.tipo_faturamento}</td>
                <td>{cliente.email_faturamento}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {open && currentCliente && (
        <div className="cli-modal-overlay">
          <div className="cli-modal-content">
            <h3 className="cli-modal-title">Editar Cliente</h3>
            <div className="cli-form-group">
              <label className="cli-form-label">Tipo</label>
              <input
                type="text"
                name="tipo"
                value={currentCliente?.tipo || ''}
                onChange={handleInputChange}
                className="cli-form-input"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Nome do Cliente</label>
              <input
                type="text"
                name="nome_cliente"
                value={currentCliente?.nome_cliente || ''}
                onChange={handleInputChange}
                className="cli-form-input"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">CNPJ</label>
              <input
                type="text"
                name="cnpj"
                value={currentCliente?.cnpj || ''}
                onChange={handleInputChange}
                className="cli-form-input"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Endereço</label>
              <textarea
                name="endereco"
                value={currentCliente?.endereco || ''}
                onChange={handleInputChange}
                className="cli-form-textarea"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Horário de Entrega</label>
              <textarea
                name="horario_entrega"
                value={currentCliente?.horario_entrega || ''}
                onChange={handleInputChange}
                className="cli-form-input"
              />
            </div>

            {/* Campo de edição para Follow-up */}
            <div className="cli-form-group">
              <label className="cli-form-label">Follow-up do Cliente</label>
              <Select
                options={followupOptions}
                isMulti
                value={currentCliente?.cliente_followup || []}
                onChange={handleFollowupChange}
                className="cli-form-select"
              />
            </div>

            <div className="cli-form-group">
              <label className="cli-form-label">Rodotrem</label>
              <select
                name="rodotrem"
                value={currentCliente?.rodotrem || ''}
                onChange={handleInputChange}
                className="cli-form-select"
              >
                <option value="SIM">SIM</option>
                <option value="NÃO">NÃO</option>
              </select>
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Troca de Nota</label>
              <textarea
                name="troca_de_nota"
                value={currentCliente?.troca_de_nota || ''}
                onChange={handleInputChange}
                className="cli-form-textarea"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Carga/Descarga</label>
              <textarea
                name="carga_descarga"
                value={currentCliente?.carga_descarga || ''}
                onChange={handleInputChange}
                className="cli-form-textarea"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Escolta</label>
              <textarea
                name="escolta"
                value={currentCliente?.escolta || ''}
                onChange={handleInputChange}
                className="cli-form-textarea"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Pré-Stacking</label>
              <textarea
                name="pre_stacking"
                value={currentCliente?.pre_stacking || ''}
                onChange={handleInputChange}
                className="cli-form-textarea"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Entrega Adicional</label>
              <textarea
                name="entrega_adicional"
                value={currentCliente?.entrega_adicional || ''}
                onChange={handleInputChange}
                className="cli-form-textarea"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Observação</label>
              <textarea
                name="observacao"
                value={currentCliente?.observacao || ''}
                onChange={handleInputChange}
                className="cli-form-textarea"
              />
            </div>
            <div className="cli-form-group">
              <label className="cli-form-label">Prazo de Faturamento</label>
              <input
                type="number"
                name="prazo_faturamento"
                value={currentCliente?.prazo_faturamento || ''}
                onChange={handleInputChange}
                className="cli-form-input"
              />
            </div>

            {/* Campo de edição para Tipo de Faturamento */}
            <div className="cli-form-group">
              <label className="cli-form-label">Tipo de Faturamento</label>
              <Select
                options={tipoFaturamentoOptions}
                value={selectedTipoFaturamento}
                onChange={handleTipoFaturamentoChange}
                className="cli-form-select"
              />
            </div>

            <div className="cli-form-group">
              <label className="cli-form-label">Email de Faturamento</label>
              <input
                type="email"
                name="email_faturamento"
                value={currentCliente?.email_faturamento || ''}
                onChange={handleInputChange}
                className="cli-form-input"
              />
            </div>



            <div className="cli-modal-actions">
              <button onClick={handleCloseModal} className="cli-button cli-button-secondary">Cancelar</button>
              <button onClick={handleUpdate} className="cli-button cli-button-primary">Salvar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClienteList;
