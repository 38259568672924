import React from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import '../styles/Motorista.css';

function MotoristaForm({ onClose }) {
  const { register, handleSubmit, formState: { errors }, setValue } = useForm();

  const formatCPF = (value) => {
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 11); // Remove tudo que não é dígito e limita a 11 caracteres
    return sanitizedValue.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatWhatsapp = (value) => {
    const sanitizedValue = value.replace(/\D/g, '').slice(0, 15); // Remove tudo que não é dígito e limita a 15 caracteres
    return sanitizedValue.replace(/(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})/, "+$1 $2 $3 $4-$5");
  };

  const onSubmit = async (data) => {
    data.cpf = data.cpf.replace(/\D/g, '');  // Remove pontos e hífens
    data.whatsapp = data.whatsapp.replace(/\D/g, '');  // Remove espaços, parênteses, etc.

    try {
      const token = localStorage.getItem('token'); // Recupera o token JWT do localStorage

      await axios.post(`${process.env.REACT_APP_API_URL}/motoristas`, data, {
        headers: {
          Authorization: `Bearer ${token}` // Envia o token no cabeçalho Authorization
        }
      });
      
      alert('Motorista cadastrado com sucesso!');
      onClose(); // Fecha o formulário após o cadastro bem-sucedido
    } catch (error) {
      console.error('Erro ao cadastrar motorista:', error);
      alert('Erro ao cadastrar motorista');
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="mot-form">
      <div className="mot-form-group">
        <label className="mot-form-label">Nome</label>
        <input 
          {...register('nome', { required: true })} 
          className="mot-form-input"
        />
        {errors.nome && <span className="mot-form-error">Este campo é obrigatório</span>}
      </div>
      <div className="mot-form-group">
        <label className="mot-form-label">CPF</label>
        <input 
          {...register('cpf', { 
            required: true, 
            onChange: (e) => setValue('cpf', formatCPF(e.target.value)),
            pattern: /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/,
            maxLength: 14 // Limita a entrada para 14 caracteres incluindo pontos e hífen
          })} 
          className="mot-form-input"
        />
        {errors.cpf && <span className="mot-form-error">CPF deve estar no formato 000.000.000-00</span>}
      </div>
      <div className="mot-form-group">
        <label className="mot-form-label">WhatsApp</label>
        <input 
          {...register('whatsapp', { 
            required: false, 
            onChange: (e) => setValue('whatsapp', formatWhatsapp(e.target.value)),
            pattern: /^\+[0-9]{2} [0-9]{2} [0-9]{1} [0-9]{4}-[0-9]{4}$/,
            maxLength: 20 // Limita a entrada para 20 caracteres no formato +55 11 9 8992-9199
          })} 
          className="mot-form-input"
        />
        {errors.whatsapp && <span className="mot-form-error">WhatsApp deve estar no formato +55 11 9 8992-9199</span>}
      </div>
      <div className="mot-form-actions">
        <button type="button" onClick={onClose} className="mot-button mot-button-secondary">Cancelar</button>
        <button type="submit" className="mot-button mot-button-primary">Cadastrar Motorista</button>
      </div>
    </form>
  );
}

export default MotoristaForm;
