import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Select from 'react-select';
import '../styles/cliente.css';

function ClienteForm({ onClose }) {
  const { register, handleSubmit, formState: { errors }, setError, clearErrors } = useForm();
  const [cnpjError, setCnpjError] = useState(''); // Estado para armazenar o erro de CNPJ duplicado
  const [selectedFollowupOptions, setSelectedFollowupOptions] = useState([]);
  const [selectedTipoFaturamento, setSelectedTipoFaturamento] = useState(null);

  const followupOptions = [
    { value: 'Email', label: 'E-mail' },
    { value: 'Whatsapp', label: 'WhatsApp' },
  ];

  const tipoFaturamentoOptions = [
    { value: 'Boleto', label: 'Boleto' },
    { value: 'Pix', label: 'Pix' },
    { value: 'Transferência', label: 'Transferência' },
  ];

  const onSubmit = async (data) => {
    try {
      const token = localStorage.getItem('token');
      data.cliente_followup = selectedFollowupOptions.map(option => option.value);
      await axios.post(`${process.env.REACT_APP_API_URL}/clientes`, data, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      alert('Cliente cadastrado com sucesso!');
      setCnpjError(''); // Limpa a mensagem de erro, se houver
      onClose(); // Fechar o formulário após o cadastro bem-sucedido
    } catch (error) {
      console.error('Erro ao cadastrar cliente:', error);
      alert('Erro ao cadastrar cliente');
    }
  };

  const checkCnpjExists = async (cnpj) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/clientes/check-cnpj/${cnpj}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.data.exists) {
        setCnpjError('Este CNPJ já está cadastrado.');
        setError('cnpj', {
          type: 'manual',
          message: 'Este CNPJ já está cadastrado.'
        });
      } else {
        setCnpjError('');
        clearErrors('cnpj');
      }
    } catch (error) {
      console.error('Erro ao verificar CNPJ:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="cli-form">
      <div className="cli-form-group">
        <label className="cli-form-label">Tipo</label>
        <input {...register('tipo')} className="cli-form-input" />
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Nome do Cliente</label>
        <input {...register('nome_cliente', { required: true })} className="cli-form-input" />
        {errors.nome_cliente && <span className="cli-form-error">Este campo é obrigatório</span>}
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">CNPJ</label>
        <input
          {...register('cnpj', { required: true, pattern: /^[0-9]{14}$/ })}
          className="cli-form-input"
          onBlur={(e) => checkCnpjExists(e.target.value)} // Verifica o CNPJ quando o campo perde o foco
        />
        {errors.cnpj && <span className="cli-form-error">{errors.cnpj.message || 'CNPJ deve ter 14 dígitos'}</span>}
        {cnpjError && <span className="cli-form-error">{cnpjError}</span>}
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Endereço</label>
        <textarea {...register('endereco')} className="cli-form-textarea" />
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Horário de Entrega</label>
        <textarea {...register('horario_entrega')} className="cli-form-input" />
      </div>

      {/* Campo de seleção múltipla para Follow-up */}
      <div className="cli-form-group">
        <label className="cli-form-label">Follow-up do Cliente</label>
        <Select
          options={followupOptions}
          isMulti
          value={selectedFollowupOptions}
          onChange={setSelectedFollowupOptions}
          className="cli-form-select"
        />
      </div>

      <div className="cli-form-group">
        <label className="cli-form-label">Rodotrem</label>
        <select {...register('rodotrem')} className="cli-form-select">
          <option value="SIM">SIM</option>
          <option value="NÃO">NÃO</option>
        </select>
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Troca de Nota</label>
        <textarea {...register('troca_de_nota')} className="cli-form-textarea" />
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Carga/Descarga</label>
        <textarea {...register('carga_descarga')} className="cli-form-textarea" />
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Escolta</label>
        <textarea {...register('escolta')} className="cli-form-textarea" />
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Pré-Stacking</label>
        <textarea {...register('pre_stacking')} className="cli-form-textarea" />
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Entrega Adicional</label>
        <textarea {...register('entrega_adicional')} className="cli-form-textarea" />
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Observação</label>
        <textarea {...register('observacao')} className="cli-form-textarea" />
      </div>
      <div className="cli-form-group">
        <label className="cli-form-label">Prazo de Faturamento</label>
        <input type="number" {...register('prazo_faturamento')} className="cli-form-input" />
      </div>

      {/* Campo de seleção para Tipo de Faturamento */}
      <div className="cli-form-group">
        <label className="cli-form-label">Tipo de Faturamento</label>
        <Select
          options={tipoFaturamentoOptions}
          value={selectedTipoFaturamento}
          onChange={setSelectedTipoFaturamento}
          className="cli-form-select"
        />
      </div>

      <div className="cli-form-group">
        <label className="cli-form-label">Email de Faturamento</label>
        <input type="email" {...register('email_faturamento')} className="cli-form-input" />
      </div>


      <div className="cli-form-actions">
        <button type="button" onClick={onClose} className="cli-button cli-button-secondary">Cancelar</button>
        <button type="submit" className="cli-button cli-button-primary">Cadastrar Cliente</button>
      </div>
    </form>
  );
}

export default ClienteForm;
