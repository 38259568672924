import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/ImportacaoBuscar.css';

// Função para formatar datas e horários
const formatDate = (dateString, showTime = false) => {
    if (!dateString) return '';

    let date;

    if (dateString.includes('T')) {
        date = new Date(dateString);
    } else {
        date = new Date(dateString + 'Z');
    }

    if (isNaN(date.getTime())) {
        return '';
    }

    const options = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
    const formattedDate = date.toLocaleDateString('pt-BR', options);

    if (showTime) {
        const timeOptions = { hour: '2-digit', minute: '2-digit', timeZone: 'UTC', hour12: false };
        const formattedTime = date.toLocaleTimeString('pt-BR', timeOptions);
        return `${formattedDate} ${formattedTime}`;
    }

    return formattedDate;
};

// Função para formatar valores monetários
const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value || 0);
};

// Mapeamento de habilidades necessárias para ver cada campo
const fieldVisibilityMap = {
    id: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    data: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    follow_up: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    observacao_fup: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    origem: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    cidade_origem: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    entrega: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    cidade_entrega: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    faturar: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    observacao_operacional: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    horario_coleta: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    horario_entrega: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    referencia: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    tipo_container: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    unidade: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    nome_motorista: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    cpf: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    cavalo: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    carreta: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    observacao_agenciamento: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    gr: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    observacao_gr: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    mercadoria: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    valor_mercadoria: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    peso_mercadoria: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    status_nf: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    pedido: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    cte: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    status_guia: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    free_time: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    armador: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    terminal_devolucao: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    horario_devolucao: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    status_devolucao: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    status_ric: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    cobranca_vazio: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    observacao_vazio: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    termo_avaria: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
    valor_venda_frete: ['financeiro', 'gerente', 'superadmin', 'admin'],
    valor_compra_frete: ['financeiro', 'gerente', 'superadmin', 'admin'],
    valor_cobranca_extra: ['financeiro', 'programacao', 'superadmin', 'gerente', 'admin'],
    valor_compra_extra: ['financeiro', 'gerente', 'superadmin', 'admin'],
    cobranca_extra: ['financeiro', 'programacao', 'superadmin', 'gerente', 'admin'],
    observacao_fatura: ['financeiro', 'gerente', 'superadmin', 'admin'],
    numero_fatura: ['financeiro', 'gerente', 'superadmin', 'admin'],
};

const ImportacaoBuscar = ({ onClose }) => {
    const [importacoes, setImportacoes] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [filters, setFilters] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [userSkillLevel, setUserSkillLevel] = useState('');

    const fetchData = async (page = 1, filters = {}) => {
        setIsLoading(true);
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/importacoes`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: {
                    page,
                    limit: 50,
                    ...filters,
                },
            });

            setImportacoes(response.data.importacoes);
            setTotalPages(response.data.totalPages);
            setCurrentPage(response.data.currentPage);

            // Definir o nível de habilidade do usuário com base no token
            const decoded = JSON.parse(atob(token.split('.')[1]));
            setUserSkillLevel(decoded.skill_level || '');
        } catch (error) {
            console.error('Erro ao buscar importações:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData(currentPage, filters);
    }, [currentPage, filters]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({
            ...filters,
            [name]: value,
        });
    };

    const handlePageChange = (page) => {
        if (!isLoading) {
            setCurrentPage(page);
        }
    };

    const renderPagination = () => {
        const pages = [];
        const startPage = Math.max(1, currentPage - 2);
        const endPage = Math.min(totalPages, currentPage + 2);

        if (startPage > 1) {
            pages.push(
                <button key={1} className={`Imp_Page ${currentPage === 1 ? 'Imp_Page_Active' : ''}`} onClick={() => handlePageChange(1)} disabled={isLoading}>
                    1
                </button>
            );
            if (startPage > 2) pages.push(<span key="start-ellipsis">...</span>);
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <button key={i} className={`Imp_Page ${currentPage === i ? 'Imp_Page_Active' : ''}`} onClick={() => handlePageChange(i)} disabled={isLoading}>
                    {i}
                </button>
            );
        }

        if (endPage < totalPages) {
            if (endPage < totalPages - 1) pages.push(<span key="end-ellipsis">...</span>);
            pages.push(
                <button key={totalPages} className={`Imp_Page ${currentPage === totalPages ? 'Imp_Page_Active' : ''}`} onClick={() => handlePageChange(totalPages)} disabled={isLoading}>
                    {totalPages}
                </button>
            );
        }

        return pages;
    };

    const canViewField = (field) => {
        const fieldSkills = fieldVisibilityMap[field] || [];
        return fieldSkills.length === 0 || fieldSkills.includes(userSkillLevel);
    };

    return (
        <div className="Imp_Modal_Backdrop">
            <div className="Imp_Dialog">
                <div className="Imp_Title">Buscar Importações</div>
                <div className="Imp_Content">
                    <table className="Imp_Tabela">
                        <thead>
                            <tr>
                                {canViewField('id') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="id" placeholder="ID" value={filters.id || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('data') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="data" placeholder="Data" value={filters.data || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('follow_up') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="follow_up" placeholder="Follow Up" value={filters.follow_up || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('observacao_fup') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="observacao_fup" placeholder="Observação" value={filters.observacao_fup || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('origem') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="origem" placeholder="Origem" value={filters.origem || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('cidade_origem') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="cidade_origem" placeholder="Cidade Origem" value={filters.cidade_origem || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('entrega') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="entrega" placeholder="Entrega" value={filters.entrega || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('cidade_entrega') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="cidade_entrega" placeholder="Cidade Entrega" value={filters.cidade_entrega || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('faturar') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="faturar" placeholder="Faturar" value={filters.faturar || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('observacao_operacional') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="observacao_operacional" placeholder="Observação Operacional" value={filters.observacao_operacional || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('horario_coleta') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="horario_coleta" placeholder="Horário Coleta" value={filters.horario_coleta || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('horario_entrega') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="horario_entrega" placeholder="Horário Entrega" value={filters.horario_entrega || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('referencia') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="referencia" placeholder="Referência" value={filters.referencia || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('tipo_container') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="tipo_container" placeholder="Tipo Container" value={filters.tipo_container || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('unidade') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="unidade" placeholder="Unidade" value={filters.unidade || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('nome_motorista') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="nome_motorista" placeholder="Nome Motorista" value={filters.nome_motorista || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('cpf') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="cpf" placeholder="CPF" value={filters.cpf || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('cavalo') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="cavalo" placeholder="Cavalo" value={filters.cavalo || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('carreta') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="carreta" placeholder="Carreta" value={filters.carreta || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('observacao_agenciamento') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="observacao_agenciamento" placeholder="Observação Agenciamento" value={filters.observacao_agenciamento || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('gr') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="gr" placeholder="GR" value={filters.gr || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('observacao_gr') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="observacao_gr" placeholder="Observação GR" value={filters.observacao_gr || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('mercadoria') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="mercadoria" placeholder="Mercadoria" value={filters.mercadoria || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('valor_mercadoria') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="valor_mercadoria" placeholder="Valor Mercadoria" value={filters.valor_mercadoria || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('peso_mercadoria') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="peso_mercadoria" placeholder="Peso Mercadoria" value={filters.peso_mercadoria || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('status_nf') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="status_nf" placeholder="Status NF" value={filters.status_nf || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('pedido') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="pedido" placeholder="Pedido" value={filters.pedido || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('cte') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="cte" placeholder="CTE" value={filters.cte || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('status_guia') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="status_guia" placeholder="Status Guia" value={filters.status_guia || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('free_time') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="free_time" placeholder="Free Time" value={filters.free_time || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('armador') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="armador" placeholder="Armador" value={filters.armador || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('terminal_devolucao') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="terminal_devolucao" placeholder="Terminal Devolução" value={filters.terminal_devolucao || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('horario_devolucao') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="horario_devolucao" placeholder="Horário Devolução" value={filters.horario_devolucao || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('status_devolucao') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="status_devolucao" placeholder="Status Devolução" value={filters.status_devolucao || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('status_ric') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="status_ric" placeholder="Status RIC" value={filters.status_ric || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('cobranca_vazio') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="cobranca_vazio" placeholder="Cobrança Vazio" value={filters.cobranca_vazio || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('observacao_vazio') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="observacao_vazio" placeholder="Observação Vazio" value={filters.observacao_vazio || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('termo_avaria') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="termo_avaria" placeholder="Termo Avaria" value={filters.termo_avaria || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('valor_venda_frete') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="valor_venda_frete" placeholder="Valor Venda Frete" value={filters.valor_venda_frete || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('valor_compra_frete') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="valor_compra_frete" placeholder="Valor Compra Frete" value={filters.valor_compra_frete || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('valor_cobranca_extra') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="valor_cobranca_extra" placeholder="Valor Cobrança Extra" value={filters.valor_cobranca_extra || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('cobranca_extra') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="cobranca_extra" placeholder="Cobrança Extra" value={filters.cobranca_extra || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('valor_compra_extra') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="valor_compra_extra" placeholder="Valor Compra Extra" value={filters.valor_compra_extra || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('observacao_fatura') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="observacao_fatura" placeholder="Observação Fatura" value={filters.observacao_fatura || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                                {canViewField('numero_fatura') && (
                                    <th>
                                        <input className="Imp_Busca_Filtro" name="numero_fatura" placeholder="Número Fatura" value={filters.numero_fatura || ''} onChange={handleFilterChange} />
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {importacoes.map((importacao) => (
                                <tr key={importacao.id} className={`status-${importacao.follow_up}`}>
                                    {canViewField('id') && <td>{importacao.id}</td>}
                                    {canViewField('data') && <td>{formatDate(importacao.data)}</td>}
                                    {canViewField('follow_up') && <td>{importacao.follow_up}</td>}
                                    {canViewField('observacao_fup') && <td>{importacao.observacao_fup}</td>}
                                    {canViewField('origem') && <td>{importacao.origem}</td>}
                                    {canViewField('cidade_origem') && <td>{importacao.cidade_origem}</td>}
                                    {canViewField('entrega') && <td>{importacao.entrega}</td>}
                                    {canViewField('cidade_entrega') && <td>{importacao.cidade_entrega}</td>}
                                    {canViewField('faturar') && <td>{formatCurrency(importacao.faturar)}</td>}
                                    {canViewField('observacao_operacional') && <td>{importacao.observacao_operacional}</td>}
                                    {canViewField('horario_coleta') && <td>{formatDate(importacao.horario_coleta, true)}</td>}
                                    {canViewField('horario_entrega') && <td>{formatDate(importacao.horario_entrega, true)}</td>}
                                    {canViewField('referencia') && <td>{importacao.referencia}</td>}
                                    {canViewField('tipo_container') && <td>{importacao.tipo_container}</td>}
                                    {canViewField('unidade') && <td>{importacao.unidade}</td>}
                                    {canViewField('nome_motorista') && <td>{importacao.nome_motorista}</td>}
                                    {canViewField('cpf') && <td>{importacao.cpf}</td>}
                                    {canViewField('cavalo') && <td>{importacao.cavalo}</td>}
                                    {canViewField('carreta') && <td>{importacao.carreta}</td>}
                                    {canViewField('observacao_agenciamento') && <td>{importacao.observacao_agenciamento}</td>}
                                    {canViewField('gr') && <td>{importacao.gr}</td>}
                                    {canViewField('observacao_gr') && <td>{importacao.observacao_gr}</td>}
                                    {canViewField('mercadoria') && <td>{importacao.mercadoria}</td>}
                                    {canViewField('valor_mercadoria') && <td>{formatCurrency(importacao.valor_mercadoria)}</td>}
                                    {canViewField('peso_mercadoria') && <td>{importacao.peso_mercadoria}</td>}
                                    {canViewField('status_nf') && <td>{importacao.status_nf}</td>}
                                    {canViewField('pedido') && <td>{importacao.pedido}</td>}
                                    {canViewField('cte') && <td>{importacao.cte}</td>}
                                    {canViewField('status_guia') && <td>{importacao.status_guia}</td>}
                                    {canViewField('free_time') && <td>{formatDate(importacao.data)}</td>}
                                    {canViewField('armador') && <td>{importacao.armador}</td>}
                                    {canViewField('terminal_devolucao') && <td>{importacao.terminal_devolucao}</td>}
                                    {canViewField('horario_devolucao') && <td>{formatDate(importacao.horario_devolucao, true)}</td>}
                                    {canViewField('status_devolucao') && <td>{importacao.status_devolucao}</td>}
                                    {canViewField('status_ric') && <td>{importacao.status_ric}</td>}
                                    {canViewField('cobranca_vazio') && <td>{formatCurrency(importacao.cobranca_vazio)}</td>}
                                    {canViewField('observacao_vazio') && <td>{importacao.observacao_vazio}</td>}
                                    {canViewField('termo_avaria') && <td>{importacao.termo_avaria}</td>}
                                    {canViewField('valor_venda_frete') && <td>{formatCurrency(importacao.valor_venda_frete)}</td>}
                                    {canViewField('valor_compra_frete') && <td>{formatCurrency(importacao.valor_compra_frete)}</td>}
                                    {canViewField('valor_cobranca_extra') && <td>{formatCurrency(importacao.valor_cobranca_extra)}</td>}
                                    {canViewField('cobranca_extra') && <td>{importacao.cobranca_extra}</td>}
                                    {canViewField('valor_compra_extra') && <td>{formatCurrency(importacao.valor_compra_extra)}</td>}
                                    {canViewField('observacao_fatura') && <td>{importacao.observacao_fatura}</td>}
                                    {canViewField('numero_fatura') && <td>{importacao.numero_fatura}</td>}
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
                <div className="Imp_Footer">
                    <div className="Imp_Pagination">{renderPagination()}</div>
                    <button className="Imp_Close_Button" onClick={onClose} disabled={isLoading}>
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImportacaoBuscar;
