import React, { useState } from 'react';
import MotoristaForm from '../components/MotoristaForm';
import MotoristaList from '../components/MotoristaList';
import CarretaForm from '../components/CarretaForm';
import CarretaList from '../components/CarretaList';
import CavaloForm from '../components/CavaloForm'; // Importando o formulário de Cavalo
import CavaloList from '../components/CavaloList'; // Importando a lista de Cavalo
import '../styles/Motorista.css';
import '../styles/Carreta.css';
import '../styles/Cavalo.css'; // Importando os estilos de Cavalo

function CadastroMotoristaCarreta() {
  const [isAddMotoristaFormVisible, setAddMotoristaFormVisible] = useState(false);
  const [isAddCarretaFormVisible, setAddCarretaFormVisible] = useState(false);
  const [isAddCavaloFormVisible, setAddCavaloFormVisible] = useState(false); // Estado para o formulário de Cavalo

  const toggleAddMotoristaForm = () => {
    setAddMotoristaFormVisible(!isAddMotoristaFormVisible);
  };

  const toggleAddCarretaForm = () => {
    setAddCarretaFormVisible(!isAddCarretaFormVisible);
  };

  const toggleAddCavaloForm = () => {
    setAddCavaloFormVisible(!isAddCavaloFormVisible);
  };

  return (
    <div className="mot-page-container">
      {/* Cadastro de Motorista */}
      {isAddMotoristaFormVisible && (
        <div className="mot-modal-overlay">
          <div className="mot-modal-content mot-form-wrapper">
            <h2 className="mot-modal-title">Cadastrar Novo Motorista</h2>
            <div className="mot-form-scroll-wrapper">
              <MotoristaForm onClose={toggleAddMotoristaForm} />
            </div>
          </div>
        </div>
      )}
      <div className="mot-list-wrapper">
        <MotoristaList onAddClick={toggleAddMotoristaForm} isAddFormVisible={isAddMotoristaFormVisible} />
      </div>

      {/* Cadastro de Carreta */}
      {isAddCarretaFormVisible && (
        <div className="carreta-modal-overlay">
          <div className="carreta-modal-content carreta-form-wrapper">
            <h2 className="carreta-modal-title">Cadastrar Nova Carreta</h2>
            <div className="carreta-form-scroll-wrapper">
              <CarretaForm onClose={toggleAddCarretaForm} />
            </div>
          </div>
        </div>
      )}
      <div className="carreta-list-wrapper">
        <CarretaList onAddClick={toggleAddCarretaForm} isAddFormVisible={isAddCarretaFormVisible} />
      </div>

      {/* Cadastro de Cavalo */}
      {isAddCavaloFormVisible && (
        <div className="cavalo-modal-overlay">
          <div className="cavalo-modal-content cavalo-form-wrapper">
            <h2 className="cavalo-modal-title">Cadastrar Novo Cavalo</h2>
            <div className="cavalo-form-scroll-wrapper">
              <CavaloForm onClose={toggleAddCavaloForm} />
            </div>
          </div>
        </div>
      )}
      <div className="cavalo-list-wrapper">
        <CavaloList onAddClick={toggleAddCavaloForm} isAddFormVisible={isAddCavaloFormVisible} />
      </div>
    </div>
  );
}

export default CadastroMotoristaCarreta;
