import React from 'react';
import PedidoForm from '../components/PedidoForm';

function CriarPedido() {
  return (
    <div>
      <h1>Criar Pedido</h1>
      <PedidoForm />
    </div>
  );
}

export default CriarPedido;
