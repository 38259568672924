import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../styles/Carreta.css';

function CarretaList({ onAddClick, isAddFormVisible }) {
  const [carretas, setCarretas] = useState([]);
  const [currentCarreta, setCurrentCarreta] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false); // Estado para controlar o modal
  const [userSkillLevel, setUserSkillLevel] = useState('');

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = JSON.parse(atob(token.split('.')[1]));
        setUserSkillLevel(decoded.skill_level || '');
      }
    };

    fetchUserPermissions();
    fetchCarretas();
  }, []);

  const fetchCarretas = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/carreta`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCarretas(response.data);
    } catch (error) {
      console.error('Erro ao buscar carretas:', error);
    }
  };

  const handleOpenModal = (carreta) => {
    setCurrentCarreta(carreta);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setCurrentCarreta(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentCarreta({ ...currentCarreta, [name]: value });
  };

  const handleUpdate = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(`${process.env.REACT_APP_API_URL}/carreta/${currentCarreta.id}`, {
        placa: currentCarreta.placa // Enviando somente o campo placa
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchCarretas();
      handleCloseModal();
    } catch (error) {
      console.error('Erro ao atualizar carreta:', error);
      alert('Erro ao atualizar carreta. Por favor, tente novamente.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir esta carreta?')) {
      try {
        const token = localStorage.getItem('token');
        await axios.delete(`${process.env.REACT_APP_API_URL}/carreta/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        fetchCarretas();
        alert('Carreta excluída com sucesso!');
      } catch (error) {
        console.error('Erro ao excluir carreta:', error);
        alert('Erro ao excluir carreta');
      }
    }
  };

  return (
    <div className="carreta-list-container">
      <h2 className="carreta-list-title">Lista de Carretas</h2>
      <div className="carreta-list-controls">
        {/* Exibe o botão "Adicionar Carreta" apenas para AGENCIA e PROGRAMACAO */}
        {(userSkillLevel === 'agencia' || userSkillLevel === 'programacao') && (
          <button className="carreta-add-button" onClick={onAddClick}>
            {isAddFormVisible ? 'Cancelar' : 'Adicionar Carreta'}
          </button>
        )}
        
        <div className="carreta-list-search">
          <input
            type="text"
            placeholder="Buscar por placa"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="carreta-list-search-input"
          />
        </div>
      </div>

      <div className="carreta-table-wrapper">
        <table className="carreta-list-table">
          <thead>
          <tr className="mot-column-header">
            <th className="mot-row-number">#ID</th>
              <th>Ações</th>
              <th>Placa</th>
            </tr>
          </thead>
          <tbody>
            {carretas.filter(carreta => 
              carreta.placa.toLowerCase().includes(searchTerm.toLowerCase())
            ).map((carreta) => (
              <tr key={carreta.id}>
                
                <td className="mot-row-number">{carreta.id}</td>
                <td>
                  {/* Exibe os botões de editar e deletar apenas para AGENCIA e PROGRAMACAO */}
                  {(userSkillLevel === 'agencia' || userSkillLevel === 'programacao') && (
                    <>
                      <button onClick={() => handleOpenModal(carreta)} className="carreta-list-edit-button">✏️</button>
                      <button onClick={() => handleDelete(carreta.id)} className="carreta-list-delete-button">🗑️</button>
                    </>
                  )}
                </td>
                <td>{carreta.placa}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {open && (
        <div className="carreta-modal-overlay">
          <div className="carreta-modal-content">
            <h3 className="carreta-modal-title">Editar Carreta</h3>
            <div className="carreta-form-group">
              <label className="carreta-form-label">Placa</label>
              <input
                type="text"
                name="placa"
                value={currentCarreta?.placa || ''}
                onChange={handleInputChange}
                className="carreta-form-input"
              />
            </div>
            <div className="carreta-modal-actions">
              <button onClick={handleCloseModal} className="carreta-button carreta-button-secondary">Cancelar</button>
              <button onClick={handleUpdate} className="carreta-button carreta-button-primary">Salvar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CarretaList;
