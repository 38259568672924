import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';
import '../styles/editmodal.css';

const EditModal = ({ isOpen, onClose, onSave, currentData, userSkillLevel, onDuplicate, onDelete }) => {
  const [formData, setFormData] = useState({});
  const [motoristas, setMotoristas] = useState([]);
  const [cavalos, setCavalos] = useState([]);
  const [carretas, setCarretas] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [duplicateCount, setDuplicateCount] = useState(1);
  const [followUpModalOpen, setFollowUpModalOpen] = useState(false);
  const [selectedFollowUp, setSelectedFollowUp] = useState('');
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutos em segundos
  const [timeExpired, setTimeExpired] = useState(false); // Verifica se o tempo expirou

  // Adiciona os estados para os novos campos de seleção múltipla
  const [observacaoVazio, setObservacaoVazio] = useState([]);
  const [termoAvaria, setTermoAvaria] = useState('');
  const [cobrancaExtra, setCobrancaExtra] = useState([]);

  useEffect(() => {
    if (currentData) {
      // Inicia a edição no backend e atualiza o campo editing_start_time
      startEditing(currentData.id);
  
      const formattedData = {
        ...currentData,
        horario_coleta: formatDatetimeForInput(currentData.horario_coleta),
        horario_entrega: formatDatetimeForInput(currentData.horario_entrega),
        horario_devolucao: formatDatetimeForInput(currentData.horario_devolucao),
        data: formatDateForInput(currentData.data),
        free_time: formatDateForInput(currentData.free_time),
        valor_mercadoria: formatValorMercadoria(currentData.valor_mercadoria),
        peso_mercadoria: formatValorMercadoria(currentData.peso_mercadoria),
      };
      setFormData(formattedData);
      setSelectedFollowUp(currentData.follow_up || '');
  
      // Verifica se o tempo de edição já expirou ao abrir o modal
      checkEditTime(currentData.editing_start_time);
  
      // Inicializa os estados dos novos campos
      setObservacaoVazio(currentData.observacao_vazio ? currentData.observacao_vazio.split(',') : []);
      setTermoAvaria(currentData.termo_avaria || '');
      setCobrancaExtra(currentData.cobranca_extra ? currentData.cobranca_extra.split(',') : []);
  
      // Inicia o contador de 5 minutos ao abrir o modal
      setTimeLeft(300); // 5 minutos em segundos
      setTimeExpired(false); // Reseta a expiração do tempo
  
      // Envia requisição para iniciar a edição no backend
      axios.post(`/api/importacoes/${currentData.id}/start-editing`)
        .then(response => {
          console.log('Edição iniciada:', response.data);
        })
        .catch(error => {
          console.error('Erro ao iniciar a edição:', error);
        });
    }
  
    // Função para buscar dados relacionados
    fetchMotoristas();
    fetchCavalos();
    fetchCarretas();
    fetchClientes();
  
    // Função para controlar o tempo restante
    const timer = setInterval(() => {
      setTimeLeft(prevTime => {
        if (prevTime <= 0) {
          setTimeExpired(true); // Define que o tempo expirou
          clearInterval(timer); // Limpa o timer
          onClose(); // Fecha o modal automaticamente
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000); // Atualiza a cada segundo
  
    return () => clearInterval(timer); // Limpa o timer quando o modal for fechado
  
  }, [currentData, onClose]);
  

  // Função para checar o tempo de edição
  const checkEditTime = (editingStartTime) => {
    if (editingStartTime) {
      const startTime = new Date(editingStartTime);
      const now = new Date();
      const elapsedSeconds = (now - startTime) / 1000; // Calcula o tempo em segundos

      if (elapsedSeconds > 300) {
        // Se o tempo de edição passou de 5 minutos, expira o tempo
        setTimeExpired(true);
        resetEditingStatus(); // Reseta o status de edição
      } else {
        // Atualiza o tempo restante
        setTimeLeft(300 - elapsedSeconds);
      }
    } else {
      // Se não houver `editing_start_time`, inicia a edição
      startEditing();
    }
  };

  const startEditing = async (id) => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/importacao/${id}/start-editing`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
    } catch (error) {
      console.error('Erro ao iniciar a edição:', error);
    }
  };


  // Função para resetar o status de edição
  const resetEditingStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.put(
        `${process.env.REACT_APP_API_URL}/importacoes/${currentData.id}/reset-editing`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      onClose();
    } catch (error) {
      console.error('Erro ao resetar o status de edição:', error);
    }
  };
  // Formata o tempo restante no formato "MM:SS"
  const formatTimeLeft = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  const fetchMotoristas = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/motoristas`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setMotoristas(response.data);
    } catch (error) {
      console.error('Erro ao buscar motoristas:', error);
    }
  };

  const fetchCavalos = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/cavalo`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCavalos(response.data);
    } catch (error) {
      console.error('Erro ao buscar cavalos:', error);
    }
  };

  const fetchCarretas = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/carreta`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setCarretas(response.data);
    } catch (error) {
      console.error('Erro ao buscar carretas:', error);
    }
  };

  const fetchClientes = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/clientes`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClientes(response.data);
    } catch (error) {
      console.error('Erro ao buscar clientes:', error);
    }
  };

  const formatDateForInput = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toISOString().slice(0, 10);
  };

  const formatDatetimeForInput = (datetime) => {
    if (!datetime) return '';
    const date = new Date(datetime);
    return date.toISOString().slice(0, 16);
  };

  const formatValorMercadoria = (value) => {
    if (value === null || value === undefined || value === '') return '';
    const numericValue = parseFloat(value);
    return numericValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleMotoristaChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        nome_motorista: selectedOption.nome,
        cpf: selectedOption.cpf
      });
    } else {
      setFormData({
        ...formData,
        nome_motorista: '',
        cpf: ''
      });
    }
  };

  const handleCpfChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        nome_motorista: selectedOption.nome,
        cpf: selectedOption.cpf
      });
    } else {
      setFormData({
        ...formData,
        nome_motorista: '',
        cpf: ''
      });
    }
  };

  const handleCavaloChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        cavalo: selectedOption.placa
      });
    } else {
      setFormData({
        ...formData,
        cavalo: ''
      });
    }
  };

  const handleCarretaChange = (selectedOption) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        carreta: selectedOption.placa
      });
    } else {
      setFormData({
        ...formData,
        carreta: ''
      });
    }
  };

  const handleClienteChange = (selectedOption, field) => {
    if (selectedOption) {
      setFormData({
        ...formData,
        [field]: `${selectedOption.nome_cliente} - ${selectedOption.cnpj} - ${selectedOption.endereco}`
      });
    } else {
      setFormData({
        ...formData,
        [field]: ''
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'valor_mercadoria' || name === 'peso_mercadoria') {
      formattedValue = value.replace(/[^\d,]/g, '');
      if (formattedValue.includes(',')) {
        const parts = formattedValue.split(',');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        formattedValue = parts.join(',');
      } else {
        formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    }

    setFormData({ ...formData, [name]: formattedValue });
  };

  const handleOpenFollowUpModal = () => {
    setFollowUpModalOpen(true);
  };

  const handleCloseFollowUpModal = () => {
    setFollowUpModalOpen(false);
  };

  const validateFields = (currentStatus, newStatus) => {
    const userObligations = {
      PEN: {
        obligated: ['programacao'],
        fields: ['data', 'follow_up', 'origem', 'cidade_origem', 'entrega']
      },
      AGE: {
        obligated: ['programacao'],
        fields: [
          'data', 'follow_up', 'origem', 'cidade_origem', 'entrega', 'cidade_entrega', 'faturar', 'horario_coleta',
          'horario_entrega', 'referencia', 'tipo_container', 'unidade',
          'mercadoria', 'valor_mercadoria', 'peso_mercadoria', 'armador'
        ]
      },
      SLC: {
        obligated: ['agencia'],
        fields: ['nome_motorista', 'cpf', 'cavalo', 'carreta']
      },
      GRP: {
        obligated: ['gerenciamentoderisco'],
        fields: ['gr', 'observacao_gr']
      },
      DOC: {
        obligated: ['fiscal'],
        fields: ['cte']
      },
      VAZ: {
        obligated: ['vazio'],
        fields: ['status_ric', 'terminal_devolucao', 'horario_devolucao', 'status_devolucao']
      },
      FAT: {
        obligated: ['financeiro'],
        fields: ['numero_fatura']
      },
      ATC: {
        obligated: [''],
        fields: []
      }
    };

    // Pega os campos obrigatórios e os usuários obrigados para o status atual, ou define valores padrão vazios
    const { obligated = [], fields = [] } = userObligations[currentStatus] || {};

    // Se o usuário não estiver na lista de obrigados, não precisa validar os campos
    if (!obligated.includes(userSkillLevel)) {
      return true;
    }

    // Valida se todos os campos obrigatórios estão preenchidos
    for (let field of fields) {
      if (!formData[field]) {
        alert(`O campo ${field.replace(/_/g, ' ').toUpperCase()} é obrigatório para mudar o status para ${newStatus}`);
        return false;
      }
    }

    return true;
  };



  const handleFinalSave = () => {
    const newStatus = selectedFollowUp || formData.follow_up; // Usa o follow_up atual se não for selecionado um novo
    const currentStatus = formData.follow_up;

    // Valida se os campos obrigatórios para o novo status estão preenchidos
    if (!validateFields(currentStatus, newStatus)) {
      return;
    }

    // Verifica se o tempo de edição expirou
    if (timeExpired) {
      alert("O tempo de edição para este registro expirou.");
      onClose(); // Fecha o modal automaticamente
      return;
    }

    // Formata os valores numéricos e campos de data antes de enviar para o backend
    const numericFormData = Object.entries(formData).reduce((acc, [key, value]) => {
      if (key === 'valor_mercadoria' || key === 'peso_mercadoria') {
        acc[key] = parseFloat(value.replace(/\./g, '').replace(',', '.')); // Converte para número formatado
      } else if (['data', 'horario_coleta', 'horario_entrega', 'free_time', 'horario_devolucao'].includes(key)) {
        acc[key] = value ? new Date(value).toISOString() : null; // Formata as datas em ISO
      } else {
        acc[key] = value === '' ? null : value; // Se o campo estiver vazio, define como null
      }
      return acc;
    }, {});

    // Adiciona campos adicionais que precisam ser salvos
    numericFormData.observacao_vazio = observacaoVazio.join(','); // Junta os valores de observação vazio
    numericFormData.termo_avaria = termoAvaria; // Adiciona termo de avaria
    numericFormData.cobranca_extra = cobrancaExtra.join(','); // Junta os valores de cobrança extra

    // Chama a função onSave, que provavelmente faz a chamada à API para salvar os dados
    onSave({ ...numericFormData, follow_up: newStatus });

    // Fecha o modal de follow-up se estiver aberto
    handleCloseFollowUpModal();

    // Fecha o modal principal após salvar
    onClose();
  };


  const getNextStatuses = (currentStatus) => {
    const statusFlow = {
      PEN: ['AGE', 'ATC'],
      AGE: ['SLC', 'ATC'],
      SLC: ['GRP', 'ATC'],
      GRP: ['DOC', 'ATC'],
      DOC: ['VIA', 'ATC'],
      VIA: ['CLI', 'ATC'],
      CLI: ['VAZ', 'ATC'],
      VAZ: ['FAT', 'ATC'],
      FAT: ['FIM', 'ATC'],
      ATC: ['PEN']
    };

    return statusFlow[currentStatus] || [];
  };

  const handleDuplicate = () => {
    setIsDuplicateModalOpen(true);
  };

  const confirmDuplicate = () => {
    if (typeof onDuplicate === 'function') {
      onDuplicate({
        origem: formData.origem,
        cidade_origem: formData.cidade_origem,
        entrega: formData.entrega,
        cidade_entrega: formData.cidade_entrega,
        faturar: formData.faturar,
        duplicateCount,
      });
      setIsDuplicateModalOpen(false);
      onClose();
    } else {
      console.error('onDuplicate function is not defined');
    }
  };

  const handleDelete = () => {
    if (window.confirm('Tem certeza que deseja deletar o registro?')) {
      if (window.confirm('Essa ação não pode ser desfeita. Deseja continuar?')) {
        onDelete(formData.id);
        onClose();
      }
    }
  };

  const isFieldEditable = (fieldName) => {
    if (userSkillLevel === 'visualizacao') return false;
    if (formData.follow_up === 'ATT' || userSkillLevel === 'gerente' || userSkillLevel === 'superadmin') return true;

    const fieldRules = {
      data: 'programacao',
      follow_up: 'programacao',
      observacao_fup: 'followup',
      origem: 'programacao',
      cidade_origem: 'programacao',
      entrega: 'programacao',
      cidade_entrega: 'programacao',
      faturar: 'programacao',
      observacao_operacional: 'programacao',
      horario_coleta: 'programacao',
      horario_entrega: 'programacao',
      referencia: 'programacao',
      tipo_container: 'programacao',
      unidade: 'programacao',
      nome_motorista: 'agencia',
      cpf: 'agencia',
      cavalo: 'agencia',
      carreta: 'agencia',
      observacao_agenciamento: 'agencia',
      gr: 'gerenciamentoderisco',
      observacao_gr: 'gerenciamentoderisco',
      mercadoria: 'programacao',
      valor_mercadoria: 'programacao',
      peso_mercadoria: 'programacao',
      status_nf: 'fiscal',
      pedido: 'fiscal',
      cte: 'fiscal',
      status_guia: 'programacao',
      free_time: 'programacao',
      armador: 'programacao',
      terminal_devolucao: 'vazio',
      horario_devolucao: 'vazio',
      status_devolucao: 'vazio',
      status_ric: 'vazio',
      cobranca_vazio: 'vazio',
      observacao_vazio: 'vazio',
      termo_avaria: 'vazio',
      valor_venda_frete: 'financeiro',
      valor_compra_frete: 'financeiro',
      valor_cobranca_extra: 'financeiro,programacao',
      valor_compra_extra: 'financeiro',
      cobranca_extra: 'financeiro,programacao',
      observacao_fatura: 'financeiro',
      numero_fatura: 'financeiro',
    };

    const requiredSkill = fieldRules[fieldName];
    return requiredSkill && requiredSkill.split(',').includes(userSkillLevel);
  };

  const isFieldVisible = (fieldName) => {
    const financeFields = [
      'valor_venda_frete',
      'valor_compra_frete',
      'valor_cobranca_extra',
      'valor_compra_extra',
      'observacao_fatura',
      'numero_fatura',
    ];

    if (financeFields.includes(fieldName)) {
      return userSkillLevel === 'financeiro';
    }

    return true;
  };

  const getInputType = (key) => {
    const types = {
      data: 'date',
      horario_coleta: 'datetime-local',
      horario_entrega: 'datetime-local',
      free_time: 'date',
      horario_devolucao: 'datetime-local',
      valor_mercadoria: 'text',
      peso_mercadoria: 'text',
      cobranca_vazio: 'text',
      valor_venda_frete: 'text',
      valor_compra_frete: 'text',
      valor_cobranca_extra: 'text',
      valor_compra_extra: 'text',
      pedido: 'number',
      cte: 'number',
      numero_fatura: 'number',
    };
    return types[key] || 'text';
  };

  const renderInput = (key, value) => {
    if (key === 'armador') {
      const armadorOptions = [
        'MAERSK', 'MSC', 'COSCO', 'EVERGREEN', 'CMA CGM', 'HAPAG', 'ONE', 'PIL', 'ZIM',
        'YANG MING', 'HMM', 'OOCL', 'NILE DUTCH', 'APL', 'LOGIN', 'MOL',
        'CHINA SHIPPING', 'MERCOSUL', 'NVT', 'IOA', 'LGS', 'SEACO'
      ];

      return (
        <select
          className={`edit-modal-select ${isFieldEditable(key) ? '' : 'read-only'}`}
          id={key}
          name={key}
          value={value || ''}
          onChange={handleChange}
          disabled={!isFieldEditable(key)}
        >
          <option value="">Selecione</option>
          {armadorOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      );
    }

    if (key === 'tipo_container') {
      const tipoContainerOptions = [
        '40HC', '40DC', '40RH', '20DC', '40OT', '20OT', '20RH', '20TK',
        'CS', '40FR', '20FR'
      ];

      return (
        <select
          className={`edit-modal-select ${isFieldEditable(key) ? '' : 'read-only'}`}
          id={key}
          name={key}
          value={value || ''}
          onChange={handleChange}
          disabled={!isFieldEditable(key)}
        >
          <option value="">Selecione</option>
          {tipoContainerOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      );
    }

    if (key === 'terminal_devolucao') {
      const terminalDevolucaoOptions = [
        'ATLANTIS', 'ATM IOA', 'BRASMAR ITJ', 'BRASMAR IOA', 'BRASKARNE', 'BDR', 'CONEXÃO',
        'CONLOG', 'CINTAC', 'DEPOTRANS', 'ICEPORT', 'INTERSUL', 'LECHMAN NVT', 'LECHMAN IOA',
        'VMG IOA', 'MEDLOG', 'ROGERIO', 'OCEANIC', 'GRANELMAR', 'DEEP', 'ZIRAN IOA', 'CDI',
        'VMG SJP', 'ZIRAN PNG', 'DET', 'LOGA', 'DTA'
      ];

      return (
        <select
          className={`edit-modal-select ${isFieldEditable(key) ? '' : 'read-only'}`}
          id={key}
          name={key}
          value={value || ''}
          onChange={handleChange}
          disabled={!isFieldEditable(key)}
        >
          <option value="">Selecione</option>
          {terminalDevolucaoOptions.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}
        </select>
      );
    }

    if (key === 'status_devolucao') {
      return (
        <select
          className={`edit-modal-select ${isFieldEditable(key) ? '' : 'read-only'}`}
          id={key}
          name={key}
          value={value || ''}
          onChange={handleChange}
          disabled={!isFieldEditable(key)}
        >
          <option value="">Selecione</option>
          <option value="enviado">Enviado</option>
          <option value="pendente">Pendente</option>
        </select>
      );
    }

    if (key === 'status_ric') {
      return (
        <select
          className={`edit-modal-select ${isFieldEditable(key) ? '' : 'read-only'}`}
          id={key}
          name={key}
          value={value || ''}
          onChange={handleChange}
          disabled={!isFieldEditable(key)}
        >
          <option value="">Selecione</option>
          <option value="OK">OK</option>
          <option value="pendente">Pendente</option>
        </select>
      );
    }

    if (key === 'observacao_vazio') {
      const options = [
        { value: 'VAZIO', label: 'VAZIO' },
        { value: 'JANELA ESPECIAL', label: 'JANELA ESPECIAL' },
        { value: 'TRANSFERENCIA', label: 'TRANSFERENCIA' },
      ];
      return (
        <Select
          isMulti
          options={options}
          value={options.filter(option => observacaoVazio.includes(option.value))}
          onChange={(selectedOptions) => setObservacaoVazio(selectedOptions.map(option => option.value))}
          isDisabled={!isFieldEditable(key)}
          className="edit-modal-select"
        />
      );
    }

    if (key === 'termo_avaria') {
      return (
        <select
          className={`edit-modal-select ${isFieldEditable(key) ? '' : 'read-only'}`}
          id={key}
          name={key}
          value={termoAvaria}
          onChange={(e) => setTermoAvaria(e.target.value)}
          disabled={!isFieldEditable(key)}
        >
          <option value="">Selecione</option>
          <option value="PENDENTE">PENDENTE</option>
          <option value="OK">OK</option>
        </select>
      );
    }

    if (key === 'cobranca_extra') {
      const options = [
        'VAZIO', 'CHEIO', 'PROFIT', 'DIARIA', 'DESCARGA', 'CARREGAMENTO', 'DTA',
        'AZ IOA', 'AZ NVT', 'AZ PNG', 'AZ LAGES', 'ESCOLTA', 'JANELA ESPECIAL',
        'IMO', 'DESLOCAMENTO', 'ENTREGA ADICIONAL'
      ].map(item => ({ value: item, label: item }));

      return (
        <Select
          isMulti
          options={options}
          value={options.filter(option => cobrancaExtra.includes(option.value))}
          onChange={(selectedOptions) => setCobrancaExtra(selectedOptions.map(option => option.value))}
          isDisabled={!isFieldEditable(key)}
          className="edit-modal-select"
        />
      );
    }

    return (
      <input
        className={`edit-modal-input ${isFieldEditable(key) ? '' : 'read-only'}`}
        type={getInputType(key)}
        id={key}
        name={key}
        value={value || ''}
        onChange={handleChange}
        readOnly={!isFieldEditable(key)}
      />
    );
  };

  const canAdvanceStatus = (userSkillLevel, currentStatus) => {
    const allowedToAdvance = {
      programacao: ['PEN', 'AGE', 'ATC'],
      agencia: ['SLC', 'ATC'],
      gerenciamentoderisco: ['GRP', 'ATC'],
      fiscal: ['DOC', 'FAT', 'ATC'],
      followup: ['VIA', 'CLI', 'ATC'],
      vazio: ['VAZ', 'ATC'],
      financeiro: ['FAT', 'FIM', 'ATC']
    };

    return allowedToAdvance[userSkillLevel]?.includes(currentStatus);
  };

  const findClienteByNomeCNPJ = (nomeCNPJ) => {
    if (!nomeCNPJ) return null; // Verifica se o valor existe
    const [nome_cliente] = nomeCNPJ.split(' - ');
    return clientes.find(cliente => cliente.nome_cliente === nome_cliente);
  };

  if (!isOpen) return null;

  return (
    <div className="edit-modal-overlay">
      <div className="edit-modal">
        <button className="edit-modal-close-button" onClick={onClose}>✕</button>
        <h2 className="edit-modal-title">Editar Registro de Importação</h2>

        {/* Exibe o aviso de tempo expirado ou o tempo restante */}
        {timeExpired ? (
          <div className="edit-modal-expired">
            <p>O tempo de edição para este registro expirou.</p>
          </div>
        ) : (
          <div className="edit-modal-timer">
            <p>Tempo restante: {formatTimeLeft(timeLeft)}</p>
          </div>
        )}

        {/* Ações do topo, como duplicar e deletar */}
        <div className="edit-modal-actions-top">
          {userSkillLevel === 'programacao' && (
            <>
              <button onClick={handleDuplicate} className="edit-modal-button edit-modal-button-duplicate">Duplicar</button>
              <button onClick={handleDelete} className="edit-modal-button edit-modal-button-delete">Deletar</button>
            </>
          )}
        </div>

        {/* Conteúdo principal do formulário de edição */}
        <div className="edit-modal-content">
          <form>
            {Object.entries(formData).map(([key, value]) => {
              if (key === 'nome_motorista') {
                return (
                  <div className="edit-modal-form-group" key={key}>
                    <label className="edit-modal-label">Motorista</label>
                    <Select
                      options={motoristas}
                      getOptionLabel={(motorista) => motorista.nome}
                      getOptionValue={(motorista) => motorista.id}
                      value={motoristas.find(m => m.nome === formData.nome_motorista)}
                      onChange={handleMotoristaChange}
                      isClearable
                      placeholder="Selecione o motorista..."
                      isDisabled={!isFieldEditable('nome_motorista')}
                    />
                  </div>
                );
              }
              if (key === 'cpf') {
                return (
                  <div className="edit-modal-form-group" key={key}>
                    <label className="edit-modal-label">CPF</label>
                    <Select
                      options={motoristas}
                      getOptionLabel={(motorista) => motorista.cpf}
                      getOptionValue={(motorista) => motorista.id}
                      value={motoristas.find(m => m.cpf === formData.cpf)}
                      onChange={handleCpfChange}
                      isClearable
                      placeholder="Selecione o CPF..."
                      isDisabled={!isFieldEditable('cpf')}
                    />
                  </div>
                );
              }
              if (key === 'cavalo') {
                return (
                  <div className="edit-modal-form-group" key={key}>
                    <label className="edit-modal-label">Cavalo</label>
                    <Select
                      options={cavalos}
                      getOptionLabel={(cavalo) => cavalo.placa}
                      getOptionValue={(cavalo) => cavalo.id}
                      value={cavalos.find(c => c.placa === formData.cavalo)}
                      onChange={handleCavaloChange}
                      isClearable
                      placeholder="Selecione o cavalo..."
                      isDisabled={!isFieldEditable('cavalo')}
                    />
                  </div>
                );
              }
              if (key === 'carreta') {
                return (
                  <div className="edit-modal-form-group" key={key}>
                    <label className="edit-modal-label">Carreta</label>
                    <Select
                      options={carretas}
                      getOptionLabel={(carreta) => carreta.placa}
                      getOptionValue={(carreta) => carreta.id}
                      value={carretas.find(c => c.placa === formData.carreta)}
                      onChange={handleCarretaChange}
                      isClearable
                      placeholder="Selecione a carreta..."
                      isDisabled={!isFieldEditable('carreta')}
                    />
                  </div>
                );
              }
              if (['origem', 'entrega', 'faturar'].includes(key)) {
                return (
                  <div className="edit-modal-form-group" key={key}>
                    <label className="edit-modal-label">{key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}</label>
                    <Select
                      options={clientes}
                      getOptionLabel={(cliente) => `${cliente.nome_cliente} - ${cliente.cnpj} - ${cliente.endereco}`}
                      getOptionValue={(cliente) => cliente.id}
                      value={findClienteByNomeCNPJ(formData[key])}
                      onChange={(option) => handleClienteChange(option, key)}
                      isClearable
                      placeholder="Selecione o cliente..."
                      isDisabled={!isFieldEditable(key)}
                    />
                  </div>
                );
              }

              return isFieldVisible(key) && (
                <div className="edit-modal-form-group" key={key}>
                  <label className="edit-modal-label" htmlFor={key}>
                    {key.replace(/_/g, ' ').charAt(0).toUpperCase() + key.replace(/_/g, ' ').slice(1)}
                  </label>
                  {renderInput(key, value)}
                </div>
              );
            })}
          </form>
        </div>

        {/* Botões de ação no final */}
        <div className="edit-modal-actions">
          <button onClick={onClose} className="edit-modal-button edit-modal-button-delete">Cancelar</button>
          <button onClick={handleOpenFollowUpModal} className="edit-modal-button edit-modal-button-save">Salvar</button>
        </div>
      </div>

      {/* Modal de follow-up */}
      {followUpModalOpen && (
        <div className="edit-modal-overlay">
          <div className="edit-modal-follow-up">
            <h3 className="edit-modal-title">Escolher Follow Up</h3>
            <select
              className="edit-modal-select"
              value={selectedFollowUp}
              onChange={(e) => setSelectedFollowUp(e.target.value)}
            >
              {canAdvanceStatus(userSkillLevel, formData.follow_up) ? (
                <>
                  <option value="">Selecione um FUP ou confirme para salvar.</option>
                  {getNextStatuses(formData.follow_up).map((option) => (
                    <option key={option} value={option}>{option}</option>
                  ))}
                </>
              ) : (
                <option value="">Confirmar para salvar.</option>
              )}
            </select>
            <div className="edit-modal-actions">
              <button onClick={handleCloseFollowUpModal} className="edit-modal-button edit-modal-button-cancel">Cancelar</button>
              <button onClick={handleFinalSave} className="edit-modal-button edit-modal-button-save">Confirmar</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de duplicação */}
      {isDuplicateModalOpen && (
        <div className="edit-modal-overlay">
          <div className="edit-modal">
            <h2 className="edit-modal-title">Duplicar Registros</h2>
            <div className="edit-modal-content">
              <form>
                {/* Campo Origem */}
                <div className="edit-modal-form-group">
                  <label className="edit-modal-label">Origem</label>
                  <Select
                    options={clientes}
                    getOptionLabel={(cliente) => `${cliente.nome_cliente} - ${cliente.cnpj} - ${cliente.endereco}`}
                    getOptionValue={(cliente) => cliente.id}
                    value={findClienteByNomeCNPJ(formData.origem)}
                    onChange={(option) => handleClienteChange(option, 'origem')}
                    isClearable
                    placeholder="Selecione o cliente..."
                    isDisabled={!isFieldEditable('origem')}
                  />
                </div>

                {/* Campo Cidade Origem */}
                <div className="edit-modal-form-group">
                  <label className="edit-modal-label">Cidade Origem</label>
                  <input
                    className="edit-modal-input"
                    type="text"
                    name="cidade_origem"
                    value={formData.cidade_origem || ''}
                    onChange={handleChange}
                  />
                </div>

                {/* Campo Entrega */}
                <div className="edit-modal-form-group">
                  <label className="edit-modal-label">Entrega</label>
                  <Select
                    options={clientes}
                    getOptionLabel={(cliente) => `${cliente.nome_cliente} - ${cliente.cnpj} - ${cliente.endereco}`}
                    getOptionValue={(cliente) => cliente.id}
                    value={findClienteByNomeCNPJ(formData.entrega)}
                    onChange={(option) => handleClienteChange(option, 'entrega')}
                    isClearable
                    placeholder="Selecione o cliente..."
                    isDisabled={!isFieldEditable('entrega')}
                  />
                </div>

                {/* Campo Cidade Entrega */}
                <div className="edit-modal-form-group">
                  <label className="edit-modal-label">Cidade Entrega</label>
                  <input
                    className="edit-modal-input"
                    type="text"
                    name="cidade_entrega"
                    value={formData.cidade_entrega || ''}
                    onChange={handleChange}
                  />
                </div>

                {/* Campo Faturar */}
                <div className="edit-modal-form-group">
                  <label className="edit-modal-label">Faturar</label>
                  <Select
                    options={clientes}
                    getOptionLabel={(cliente) => `${cliente.nome_cliente} - ${cliente.cnpj} - ${cliente.endereco}`}
                    getOptionValue={(cliente) => cliente.id}
                    value={findClienteByNomeCNPJ(formData.faturar)}
                    onChange={(option) => handleClienteChange(option, 'faturar')}
                    isClearable
                    placeholder="Selecione o cliente..."
                    isDisabled={!isFieldEditable('faturar')}
                  />
                </div>

                {/* Campo Número de Duplicações */}
                <div className="edit-modal-form-group">
                  <label className="edit-modal-label" htmlFor="duplicateCount">Número de Duplicações:</label>
                  <input
                    className="edit-modal-input"
                    type="number"
                    id="duplicateCount"
                    name="duplicateCount"
                    value={duplicateCount}
                    onChange={(e) => setDuplicateCount(Math.max(1, Math.min(20, parseInt(e.target.value, 10))))}
                    min="1"
                    max="20"
                  />
                </div>
              </form>
            </div>
            <div className="edit-modal-actions">
              <button onClick={() => setIsDuplicateModalOpen(false)} className="edit-modal-button edit-modal-button-cancel">Cancelar</button>
              <button onClick={confirmDuplicate} className="edit-modal-button edit-modal-button-save">Duplicar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );

};

export default EditModal;
