import React from 'react';
import { useForm } from 'react-hook-form';

function PedidoForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    // console.log(data);
    // Chamar API para salvar pedido
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div>
        <label>Rota</label>
        <input {...register('rota', { required: true })} />
        {errors.rota && <span>Este campo é obrigatório</span>}
      </div>
      <div>
        <label>Tipo de Frete</label>
        <input {...register('tipoFrete', { required: true })} />
        {errors.tipoFrete && <span>Este campo é obrigatório</span>}
      </div>
      <button type="submit">Criar Pedido</button>
    </form>
  );
}

export default PedidoForm;
