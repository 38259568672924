// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
    display: block;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
    padding-top: 60px;
  }
  
  .modal-content {
    background-color: #fefefe;
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
  }
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  

  .edit-modal-input,
.edit-modal-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 16px;
}

.edit-modal-input.read-only,
.edit-modal-textarea.read-only {
  background-color: #5f5f5f; /* Fundo cinza claro para indicar que é somente leitura */
  border: 1px solid #ddd; /* Borda mais clara */
  cursor: not-allowed; /* Cursor para indicar que o campo não pode ser editado */
  color: #fff;
}

.edit-modal-input.read-only:focus,
.edit-modal-textarea.read-only:focus {
  outline: none; /* Remove o foco visual */
  box-shadow: none; /* Remove qualquer sombra de foco */
}
`, "",{"version":3,"sources":["webpack://./src/styles/Modal.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,eAAe;IACf,UAAU;IACV,OAAO;IACP,MAAM;IACN,WAAW;IACX,YAAY;IACZ,cAAc;IACd,4BAA4B;IAC5B,iCAAiC;IACjC,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,eAAe;IACf,iBAAiB;EACnB;;EAEA;;IAEE,YAAY;IACZ,qBAAqB;IACrB,eAAe;EACjB;;;EAGA;;EAEA,WAAW;EACX,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,mBAAmB;AACrB;;AAEA;;EAEE,yBAAyB,EAAE,yDAAyD;EACpF,sBAAsB,EAAE,qBAAqB;EAC7C,mBAAmB,EAAE,yDAAyD;EAC9E,WAAW;AACb;;AAEA;;EAEE,aAAa,EAAE,yBAAyB;EACxC,gBAAgB,EAAE,mCAAmC;AACvD","sourcesContent":[".modal {\n    display: block;\n    position: fixed;\n    z-index: 1;\n    left: 0;\n    top: 0;\n    width: 100%;\n    height: 100%;\n    overflow: auto;\n    background-color: rgb(0,0,0);\n    background-color: rgba(0,0,0,0.4);\n    padding-top: 60px;\n  }\n  \n  .modal-content {\n    background-color: #fefefe;\n    margin: 5% auto;\n    padding: 20px;\n    border: 1px solid #888;\n    width: 80%;\n  }\n  \n  .close {\n    color: #aaa;\n    float: right;\n    font-size: 28px;\n    font-weight: bold;\n  }\n  \n  .close:hover,\n  .close:focus {\n    color: black;\n    text-decoration: none;\n    cursor: pointer;\n  }\n  \n\n  .edit-modal-input,\n.edit-modal-textarea {\n  width: 100%;\n  padding: 8px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n  font-size: 14px;\n  margin-top: 4px;\n  margin-bottom: 16px;\n}\n\n.edit-modal-input.read-only,\n.edit-modal-textarea.read-only {\n  background-color: #5f5f5f; /* Fundo cinza claro para indicar que é somente leitura */\n  border: 1px solid #ddd; /* Borda mais clara */\n  cursor: not-allowed; /* Cursor para indicar que o campo não pode ser editado */\n  color: #fff;\n}\n\n.edit-modal-input.read-only:focus,\n.edit-modal-textarea.read-only:focus {\n  outline: none; /* Remove o foco visual */\n  box-shadow: none; /* Remove qualquer sombra de foco */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
