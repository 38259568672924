import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaCalendarAlt, FaFileExcel } from 'react-icons/fa';
import '../styles/relatorio.css';

const Relatorio = () => {
  const [dataInicio, setDataInicio] = useState('');
  const [dataFim, setDataFim] = useState('');
  const [progresso, setProgresso] = useState(0);
  const [modalAberto, setModalAberto] = useState(false);
  const [erro, setErro] = useState('');

  useEffect(() => {
    let interval;
    if (modalAberto && progresso < 100) {
      interval = setInterval(() => {
        setProgresso((prevProgress) => (prevProgress < 90 ? prevProgress + 10 : prevProgress));
      }, 500);
    }
    return () => clearInterval(interval);
  }, [modalAberto, progresso]);

  const validarDatas = () => {
    if (!dataInicio || !dataFim) {
      setErro('Por favor, selecione ambas as datas.');
      return false;
    }
    if (new Date(dataInicio) > new Date(dataFim)) {
      setErro('A data de início deve ser anterior à data de fim.');
      return false;
    }
    setErro('');
    return true;
  };

  const handleExportarExcel = async () => {
    if (!validarDatas()) return;

    setModalAberto(true);
    setProgresso(0);
    
    try {
      const token = localStorage.getItem('token');
      
      const params = {
        dataInicio,
        dataFim,
      };

      const response = await axios.get(`${process.env.REACT_APP_API_URL}/exportar-excel`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params,
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'relatorio_import.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
      setProgresso(100);
      setTimeout(() => setModalAberto(false), 1000);
    } catch (error) {
      console.error('Erro ao exportar os dados para Excel:', error);
      setErro('Ocorreu um erro ao exportar os dados. Por favor, tente novamente.');
      setModalAberto(false);
    }
  };

  return (
    <div className="relatorio-container">
      <h1>Relatório de Importações</h1>
      
      <div className="date-range-container">
        <div className="date-input-wrapper">
          <h1>Data de Início</h1>
          <FaCalendarAlt className="calendar-icon" />
          
          <input
            type="date"
            value={dataInicio}
            onChange={(e) => setDataInicio(e.target.value)}
            aria-label="Data de Início"
          />
        </div>
        
        <div className="date-input-wrapper">
        <h1>Data de Fim</h1>
          <FaCalendarAlt className="calendar-icon" /> 
          <input
            type="date"
            value={dataFim}
            onChange={(e) => setDataFim(e.target.value)}
            aria-label="Data de Fim"
          />
        </div>
      </div>
      
      {erro && <p className="erro-mensagem">{erro}</p>}
      
      <button className="relatorio-button" onClick={handleExportarExcel}>
        <FaFileExcel className="button-icon" />
        Exportar para Excel
      </button>
      
      {modalAberto && (
        <div className="modal-progresso">
          <div className="modal-conteudo">
            <h2>Exportando...</h2>
            <p>Progresso: {progresso}%</p>
            <div className="progress-bar">
              <div className="progress-bar-fill" style={{ width: `${progresso}%` }}></div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Relatorio;