import React, { useEffect, useState, memo, useCallback, useMemo } from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';
import DatePicker from 'react-datepicker';
import Modal from 'react-modal';
import 'react-datepicker/dist/react-datepicker.css';
import { getImportacoes, updateImportacao, createImportacao } from '../services/apiService';
import EditModal from '../components/EditModal';
import AddModal from '../components/AddModal';
import ImportacaoBuscar from '../components/ImportacaoBuscar'; // Certifique-se de importar o componente
import '../styles/Modal.css';
import '../styles/Importacao.css';

// Função para formatar datas e horários, lidando com diferentes formatos de data
const formatDate = (dateString, showTime = false) => {
  if (!dateString) return '';

  let date;

  // Tenta converter a string para um objeto Date
  if (dateString.includes('T')) {
    // Se a string já tem um formato ISO com 'T', tenta convertê-la diretamente
    date = new Date(dateString);
  } else {
    // Adiciona 'Z' para indicar UTC se for um formato sem fuso horário explícito
    date = new Date(dateString + 'Z');
  }

  // Verifica se a data é inválida
  if (isNaN(date.getTime())) {
    return ''; // Retorna string vazia se a data for inválida
  }

  // Formata a data e o horário conforme necessário
  const options = { day: '2-digit', month: '2-digit', year: 'numeric', timeZone: 'UTC' };
  const formattedDate = date.toLocaleDateString('pt-BR', options);

  if (showTime) {
    const timeOptions = { hour: '2-digit', minute: '2-digit', timeZone: 'UTC', hour12: false };
    const formattedTime = date.toLocaleTimeString('pt-BR', timeOptions);
    return `${formattedDate} ${formattedTime}`;
  }

  return formattedDate;
};

// Mapeamento dos tipos de campo
const fieldTypeMap = {
  data: 'date',
  follow_up: 'text',
  observacao_fup: 'text',
  origem: 'text',
  cidade_origem: 'text',
  entrega: 'text',
  cidade_entrega: 'text',
  faturar: 'text',
  observacao_operacional: 'text',
  horario_coleta: 'datetime-local',
  horario_entrega: 'datetime-local',
  referencia: 'text',
  tipo_container: 'text',
  unidade: 'text',
  nome_motorista: 'text',
  cpf: 'cpf',
  cavalo: 'plate',
  carreta: 'plate',
  observacao_agenciamento: 'text',
  gr: 'text',
  observacao_gr: 'text',
  mercadoria: 'text',
  valor_mercadoria: 'currency',
  peso_mercadoria: 'currency',
  status_nf: 'status',
  pedido: 'number',
  cte: 'number',
  status_guia: 'status',
  free_time: 'date',
  armador: 'text',
  terminal_devolucao: 'text',
  horario_devolucao: 'datetime-local',
  status_devolucao: 'text',
  status_ric: 'status',
  cobranca_vazio: 'currency',
  observacao_vazio: 'text',
  termo_avaria: 'text',
  valor_venda_frete: 'currency',
  valor_compra_frete: 'currency',
  valor_cobranca_extra: 'currency',
  valor_compra_extra: 'currency',
  cobranca_extra: 'text',
  observacao_fatura: 'text',
  numero_fatura: 'number',
};

// Mapeamento de habilidades necessárias para ver cada campo
const fieldVisibilityMap = {
  id: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  data: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  follow_up: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  observacao_fup: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  origem: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  cidade_origem: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  entrega: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  cidade_entrega: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  faturar: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  observacao_operacional: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  horario_coleta: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  horario_entrega: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  referencia: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  tipo_container: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  unidade: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  nome_motorista: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  cpf: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  cavalo: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  carreta: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  observacao_agenciamento: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  gr: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  observacao_gr: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  mercadoria: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  valor_mercadoria: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  peso_mercadoria: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  status_nf: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  pedido: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  cte: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  status_guia: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  free_time: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  armador: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  terminal_devolucao: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  horario_devolucao: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  status_devolucao: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  status_ric: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  cobranca_vazio: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  observacao_vazio: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  termo_avaria: ['admin', 'agencia', 'comercial', 'financeiro', 'fiscal', 'followup', 'gerenciamentoderisco', 'gerente', 'programacao', 'superadmin', 'vazio'],
  valor_venda_frete: ['financeiro', 'gerente', 'superadmin', 'admin'],
  valor_compra_frete: ['financeiro', 'gerente', 'superadmin', 'admin'],
  valor_cobranca_extra: ['financeiro', 'programacao', 'superadmin', 'gerente', 'admin'],
  valor_compra_extra: ['financeiro', 'gerente', 'superadmin', 'admin'],
  cobranca_extra: ['financeiro', 'programacao', 'superadmin', 'gerente', 'admin'],
  observacao_fatura: ['financeiro', 'gerente', 'superadmin', 'admin'],
  numero_fatura: ['financeiro', 'gerente', 'superadmin', 'admin'],
};

// Função para formatar valores de acordo com o tipo de campo
const formatFieldValue = (key, value) => {
  switch (fieldTypeMap[key]) {
    case 'date':
      return formatDate(value);  // Exibe apenas a data
    case 'datetime-local':
      return formatDate(value, true);  // Exibe data e hora
    case 'currency':
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value || 0);
    case 'number':
      return value ? Number(value).toLocaleString() : '0';
    case 'status':
      return value === 'OK' ? 'Ok' : 'Pendente';
    case 'cpf':
      return value ? value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : value;
    case 'plate':
      return value ? value.toUpperCase() : value;  // Exibe a placa em maiúsculas
    default:
      return value || '';
  }
};

// Mapa de status
const statusMap = {
  PEN: 'PEN',
  AGE: 'AGE',
  SLC: 'SLC',
  DOC: 'DOC',
  VIA: 'VIA',
  CLI: 'CLI',
  ATC: 'ATC',
  VAZ: 'VAZ',
  FIM: 'FIM',
  GRP: 'GRP',
  FAT: 'FAT',
};

// Estilos de Follow Up
const followUpStyles = {
  PEN: { backgroundColor: '#FFCC00', color: '#000' },
  AGE: { backgroundColor: '#FFFFFF', color: '#000' },
  SLC: { backgroundColor: '#808080', color: '#FFF' },
  DOC: { backgroundColor: '#FFFF00', color: '#000' },
  VIA: { backgroundColor: '#9D00FF', color: '#FFF' },
  CLI: { backgroundColor: '#0000FF', color: '#FFF' },
  ATC: { backgroundColor: '#FF0000', color: '#FFF' },
  // RIC: { backgroundColor: '#90EE90', color: '#000' },
  VAZ: { backgroundColor: '#00008B', color: '#FFF' },
  FIM: { backgroundColor: '#008000', color: '#FFF' },
  GRP: { backgroundColor: '#00FFFF', color: '#000' },
  FAT: { backgroundColor: '#8B4513', color: '#FFF' },
};

// Lista de status
const statuses = Object.keys(statusMap);

const allFields = [
  'id',
  'data',
  'follow_up',
  'observacao_fup',
  'origem',
  'cidade_origem',
  'entrega',
  'cidade_entrega',
  'faturar',
  'observacao_operacional',
  'horario_coleta',
  'horario_entrega',
  'referencia',
  'tipo_container',
  'unidade',
  'nome_motorista',
  'cpf',
  'cavalo',
  'carreta',
  'observacao_agenciamento',
  'gr',
  'observacao_gr',
  'mercadoria',
  'valor_mercadoria',
  'peso_mercadoria',
  'status_nf',
  'pedido',
  'cte',
  'status_guia',
  'free_time',
  'armador',
  'terminal_devolucao',
  'horario_devolucao',
  'status_devolucao',
  'status_ric',
  'cobranca_vazio',
  'observacao_vazio',
  'termo_avaria',
  'valor_venda_frete',
  'valor_compra_frete',
  'valor_cobranca_extra',
  'valor_compra_extra',
  'cobranca_extra',
  'observacao_fatura',
  'numero_fatura',
];

// Função para renderizar a linha de importação
const ImportacaoRow = memo(({ importacao, openEditModal, userFollowUp, userSkillLevel, selectedCell, onCellClick, visibleFields }) => {
  const { backgroundColor, color } = followUpStyles[importacao.follow_up] || { backgroundColor: '#FFFFFF', color: '#000' };
  const canEdit = userFollowUp.includes(importacao.follow_up);

  const [isModalOpen, setModalOpen] = useState(false);
  const [editingUser, setEditingUser] = useState('');

  const handleEditingClick = () => {
    if (importacao.is_editing) {
      setEditingUser(importacao.editing_user || 'Desconhecido');
      setModalOpen(true);
    }
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <h2>Registro em Edição</h2>
        <p>Este registro está sendo editado por: {editingUser}</p>
        <button onClick={() => setModalOpen(false)}>OK</button>
      </Modal>

      <tr key={importacao.id} className={`status-${importacao.follow_up}`} style={{ backgroundColor, color }}>
        <td>
          <button
            className={`imp-unique-edit-button ${importacao.is_editing ? 'editing' : 'not-editing'} ${!canEdit ? 'disabled' : ''}`}
            onClick={() => importacao.is_editing ? handleEditingClick() : openEditModal(importacao)}
          >
            {importacao.is_editing ? 'Em Edição' : 'Editar'}
          </button>


        </td>

        {Object.entries(importacao).map(([key, value]) => {
          const fieldSkills = fieldVisibilityMap[key] || [];
          const canViewField = fieldSkills.length === 0 || fieldSkills.includes(userSkillLevel);

          if (!visibleFields.includes(key) || !canViewField) {
            return null;
          }
          return (
            <td key={key} title={formatFieldValue(key, value)}>
              {formatFieldValue(key, value)}
            </td>
          );
        })}
      </tr>
    </>
  );
});

const Importacao = () => {
  const [importacoes, setImportacoes] = useState([]);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [userFollowUp, setUserFollowUp] = useState([]);
  const [userSkillLevel, setUserSkillLevel] = useState('');

  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false); // Gerenciamento de estado para abrir e fechar o modal
  const openSearchModal = () => {
    setIsSearchModalOpen(true);
  };
  const closeSearchModal = () => {
    setIsSearchModalOpen(false);
  };

  const [selectedStatuses, setSelectedStatuses] = useState(
    JSON.parse(localStorage.getItem('selectedStatuses')) || statuses
  );
  const [startDate, setStartDate] = useState(() => {
    const storedStartDate = localStorage.getItem('startDate');
    return storedStartDate ? new Date(storedStartDate) : new Date(Date.now() - 4 * 24 * 60 * 60 * 1000);
  });
  const [endDate, setEndDate] = useState(() => {
    const storedEndDate = localStorage.getItem('endDate');
    return storedEndDate ? new Date(storedEndDate) : new Date(Date.now() + 4 * 24 * 60 * 60 * 1000);
  });
  const [isDateFilterOpen, setIsDateFilterOpen] = useState(false);
  const [sortConfig, setSortConfig] = useState({ field: 'id', direction: 'desc' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCell, setSelectedCell] = useState(null);
  const [visibleFields, setVisibleFields] = useState(() => {
    const storedFields = localStorage.getItem('visibleFields');
    return storedFields ? JSON.parse(storedFields) : allFields;
  });
  const [isColumnSelectorOpen, setIsColumnSelectorOpen] = useState(false);
  const recordsPerPage = 300;

  const fetchData = useCallback(async () => {
    try {
      const limit = recordsPerPage;
      const offset = (currentPage - 1) * recordsPerPage;

      const response = await getImportacoes({
        limit: Number.isInteger(limit) ? limit : 300,
        offset: Number.isInteger(offset) ? offset : 0,
        status: selectedStatuses.join(','),
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        sortField: sortConfig.field,
        sortDirection: sortConfig.direction,
      });

      setImportacoes(response.importacoes);
      setTotalPages(Math.ceil(response.totalRecords / recordsPerPage));
    } catch (error) {
      console.error('Erro ao buscar registros de importação:', error);
    }
  }, [currentPage, selectedStatuses, startDate, endDate, sortConfig]);

  useEffect(() => {
    const fetchUserPermissions = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        const decoded = JSON.parse(atob(token.split('.')[1]));
        setUserFollowUp(decoded.follow_up ? decoded.follow_up.split(',') : []);
        setUserSkillLevel(decoded.skill_level || '');
      }
    };

    fetchUserPermissions();
    fetchData();

    const socket = io(process.env.REACT_APP_WS_URL || 'wss://datacraftbackend.sonho.digital', {
      transports: ['websocket'],
      reconnection: true,
      reconnectionAttempts: Infinity,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      timeout: 20000,
    });

    socket.on('connect', () => {
      console.log('Conectado ao servidor Socket.IO com ID:', socket.id);
    });

    socket.on('connect_error', (error) => {
      console.error('Erro de conexão Socket.IO:', error);
    });

    socket.on('atualizacaoImportacao', (updatedImportacao) => {
      setImportacoes((prevImportacoes) => {
        const index = prevImportacoes.findIndex((item) => item.id === updatedImportacao.id);
        let updatedList;

        if (index !== -1) {
          updatedList = prevImportacoes.map((item) =>
            item.id === updatedImportacao.id ? { ...item, ...updatedImportacao } : item
          );
        } else {
          updatedList = [...prevImportacoes, updatedImportacao];
        }

        return updatedList
          .filter((item) => selectedStatuses.includes(item.follow_up))
          .sort((a, b) => {
            const fieldA = a[sortConfig.field];
            const fieldB = b[sortConfig.field];
            if (sortConfig.direction === 'asc') {
              return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
            }
            return fieldA > fieldB ? -1 : fieldA < fieldB ? 1 : 0;
          });
      });
    });

    socket.on('disconnect', () => {
      console.log('Desconectado do servidor Socket.IO');
    });

    return () => {
      socket.off('atualizacaoImportacao');
      socket.disconnect();
    };
  }, [fetchData, selectedStatuses, sortConfig]);

  useEffect(() => {
    fetchData();
  }, [currentPage, selectedStatuses, startDate, endDate, sortConfig, fetchData]);

  const openEditModal = useCallback(
    async (data) => {
      const token = localStorage.getItem('token');
      const username = localStorage.getItem('username');  // Captura o nome do usuário do localStorage

      if (!username) {
        console.error('Nome de usuário não encontrado no localStorage.');
        alert('Não foi possível capturar o nome do usuário.');
        return;
      }

      if (token) {
        if (userFollowUp.includes(data.follow_up) && !data.is_editing) {
          try {
            // Envia o nome do usuário que está editando para o backend
            await updateImportacao(data.id, { is_editing: true, editing_user: username });
            setCurrentData(data);
            setEditModalOpen(true);
          } catch (error) {
            console.error('Erro ao marcar como em edição:', error);
          }
        } else if (data.is_editing) {
          alert('O registro está atualmente em edição por outro usuário.');
        } else {
          alert(`O REGISTRO está com o SETOR: ${statusMap[data.follow_up]}`);
        }
      }
    },
    [userFollowUp]
  );

  const closeEditModal = useCallback(async () => {
    try {
      if (currentData) {
        await updateImportacao(currentData.id, { is_editing: false });  // Libera o registro para edição
      }
      setEditModalOpen(false);
      setCurrentData(null);
    } catch (error) {
      console.error('Erro ao liberar o registro da edição:', error);
    }
  }, [currentData]);


  const openAddModal = useCallback(() => {
    if (userSkillLevel === 'programacao') {
      setAddModalOpen(true);
    } else {
      alert('Você não tem permissão para adicionar registros.');
    }
  }, [userSkillLevel]);

  const closeAddModal = useCallback(() => {
    setAddModalOpen(false);
  }, []);

  const handleSave = async (updatedData) => {
    try {
      await updateImportacao(updatedData.id, updatedData);
      setEditModalOpen(false);
      setCurrentData(null);
      fetchData();
    } catch (error) {
      console.error('Erro ao atualizar registro:', error);
    }
  };

  const handleDuplicate = async (duplicateData) => {
    const { origem, cidade_origem, entrega, cidade_entrega, faturar, duplicateCount } = duplicateData;

    try {
      const currentDate = new Date().toISOString();  // Data atual em formato ISO

      const newRecords = Array.from({ length: duplicateCount }, () => ({
        origem,
        cidade_origem,
        entrega,
        cidade_entrega,
        faturar,
        follow_up: 'PEN',  // Define o follow_up como 'PEN'
        data: currentDate,  // Define a data como a data atual
      }));

      // Fazer as duplicações no banco de dados
      for (const newRecord of newRecords) {
        await createImportacao(newRecord);
      }

      // Atualizar a tabela de importações após duplicação
      fetchData();
      alert(`${duplicateCount} registros foram duplicados com sucesso!`);
    } catch (error) {
      console.error('Erro ao duplicar registros:', error);
      alert('Erro ao duplicar registros.');
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm(`Você realmente deseja deletar o registro #${id}?`)) {
      if (window.confirm("Essa ação não é possível ser revertida. Tem certeza?")) {
        try {
          const token = localStorage.getItem('token');
          await axios.delete(`${process.env.REACT_APP_API_URL}/importacao/${id}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          fetchData(); // Atualiza a lista de importações após a exclusão
          alert('Registro excluído com sucesso!');
        } catch (error) {
          console.error('Erro ao excluir o registro:', error);
          alert('Erro ao excluir o registro');
        }
      }
    }
  };

  const handleAdd = async (newData) => {
    try {
      const addedData = await createImportacao(newData);
      setAddModalOpen(false);
      setImportacoes((prevImportacoes) => [...prevImportacoes, addedData].sort((a, b) => a.id - b.id));
      fetchData();
    } catch (error) {
      console.error('Erro ao adicionar registro:', error);
    }
  };

  const handleStatusChange = useCallback((status) => {
    setSelectedStatuses((prev) => {
      const newStatuses = prev.includes(status) ? prev.filter((s) => s !== status) : [...prev, status];
      localStorage.setItem('selectedStatuses', JSON.stringify(newStatuses));
      return newStatuses;
    });
  }, []);

  const handleStartDateChange = useCallback((date) => {
    setStartDate(date);
    localStorage.setItem('startDate', date.toISOString());
  }, []);

  const handleEndDateChange = useCallback((date) => {
    setEndDate(date);
    localStorage.setItem('endDate', date.toISOString());
  }, []);

  const handleSort = useCallback((field) => {
    setSortConfig((prev) => {
      let newDirection = 'asc';
      if (prev.field === field && prev.direction === 'asc') {
        newDirection = 'desc';
      } else if (prev.field === field && prev.direction === 'desc') {
        newDirection = 'asc';
      }
      return { field, direction: newDirection };
    });
  }, []);

  const clearFilters = useCallback(() => {
    const newStartDate = new Date(Date.now() - 4 * 24 * 60 * 60 * 1000);
    const newEndDate = new Date(Date.now() + 4 * 24 * 60 * 60 * 1000);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    localStorage.setItem('startDate', newStartDate.toISOString());
    localStorage.setItem('endDate', newEndDate.toISOString());
    setSelectedStatuses(statuses);
    localStorage.setItem('selectedStatuses', JSON.stringify(statuses));
    setSortConfig({ field: 'id', direction: 'desc' });
  }, []);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleCellClick = (rowId, columnName) => {
    setSelectedCell({ rowId, columnName });
  };

  const handleColumnSelectionChange = (column) => {
    setVisibleFields((prevFields) => {
      const newFields = prevFields.includes(column)
        ? prevFields.filter((field) => field !== column)
        : [...prevFields, column];
      localStorage.setItem('visibleFields', JSON.stringify(newFields));
      return newFields;
    });
  };

  const memoizedImportacoes = useMemo(() => importacoes, [importacoes]);

  return (
    <div className="imp-container">
      <h1 className="imp-title">Registros de Importação</h1>
      <div className="imp-controls">
        {userSkillLevel === 'programacao' && (
          <button className="imp-button" onClick={openAddModal}>Adicionar Registro</button>
        )}
        <button className="imp-button" onClick={openSearchModal}>
          BUSCAR
        </button>
        <button className="imp-button" onClick={() => setIsColumnSelectorOpen(!isColumnSelectorOpen)}>
          Selecionar Colunas
        </button>
        {/* Verifique se o modal está sendo renderizado quando isSearchModalOpen é true */}
        {isSearchModalOpen && <ImportacaoBuscar onClose={closeSearchModal} />}
        <div className="imp-status-filters">
          {statuses.map((status) => (
            <div key={status} className="imp-filter-option">
              <input
                type="checkbox"
                checked={selectedStatuses.includes(status)}
                onChange={() => handleStatusChange(status)}
              />
              <label>{statusMap[status]}</label>
            </div>
          ))}

          <div className="imp-date-display" onClick={() => setIsDateFilterOpen(!isDateFilterOpen)}>
            📅 {formatDate(startDate.toISOString())} - {formatDate(endDate.toISOString())}
          </div>
          {isDateFilterOpen && (
            <div className="imp-date-filter">
              <DatePicker
                selected={startDate}
                onChange={handleStartDateChange}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="Data Inicial"
              />
              <span>até</span>
              <DatePicker
                selected={endDate}
                onChange={handleEndDateChange}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="Data Final"
              />
            </div>
          )}
          <button className="imp-button" onClick={clearFilters}>Limpar Filtros</button>
        </div>
      </div>

      {isColumnSelectorOpen && (
        <div className="imp-column-selector">
          {allFields.map((field) => {
            const fieldSkills = fieldVisibilityMap[field] || [];
            const canViewField = fieldSkills.includes(userSkillLevel);

            if (!canViewField) {
              return null;
            }

            return (
              <div key={field} className="imp-column-option">
                <input
                  type="checkbox"
                  checked={visibleFields.includes(field)}
                  onChange={() => handleColumnSelectionChange(field)}
                />
                <label>{field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, ' ')}</label>
              </div>
            );
          })}
          <button className="imp-button" onClick={() => setIsColumnSelectorOpen(false)}>Salvar</button>
        </div>
      )}

      <div className="imp-table-container">
        <table className="imp-table importacao-table">
          <thead>
            <tr>
              <th>Ações</th>
              {Object.keys(memoizedImportacoes[0] || {}).map((key) => {
                const fieldSkills = fieldVisibilityMap[key] || [];
                const canViewField = fieldSkills.includes(userSkillLevel);

                if (!visibleFields.includes(key) || !canViewField) {
                  return null;
                }

                return (
                  <th key={key} onClick={() => handleSort(key)}>
                    {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, ' ')}
                    {sortConfig.field === key && (sortConfig.direction === 'asc' ? ' ▲' : ' ▼')}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {memoizedImportacoes.map((importacao) => (
              <ImportacaoRow
                key={importacao.id}
                importacao={importacao}
                openEditModal={openEditModal}
                userFollowUp={userFollowUp}
                userSkillLevel={userSkillLevel}
                selectedCell={selectedCell}
                onCellClick={handleCellClick}
                visibleFields={visibleFields} // Passa as colunas visíveis para ImportacaoRow
              />
            ))}
          </tbody>
        </table>
      </div>
      <div className="imp-pagination">
        <button
          className="imp-pagination-button"
          onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
          disabled={currentPage === 1}
        >
          Página Anterior
        </button>
        <span>Página {currentPage} de {totalPages}</span>
        <button
          className="imp-pagination-button"
          onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
          disabled={currentPage === totalPages}
        >
          Próxima Página
        </button>
      </div>
      <EditModal
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        onSave={handleSave}
        currentData={currentData}
        userSkillLevel={userSkillLevel}
        onDuplicate={handleDuplicate}
        onDelete={handleDelete}  // Passa a função de deletar para o `EditModal`
        statuses={statuses}
      />

      <AddModal
        isOpen={isAddModalOpen}
        onClose={closeAddModal}
        onAdd={handleAdd}
        userSkillLevel={userSkillLevel}
      />
    </div>
  );
};

export default Importacao;
